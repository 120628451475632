import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLogout } from '../../actions/auth';

import Profile from '../Profile';
import CreateOffer from '../CreateOffer';
import UpdateOffer from '../UpdateOffer';
import CreateOrder from '../CreateOrder';
import ShowDetalleOrder from '../ShowDetalleOrder';
import ShowDetalleOffer from '../ShowDetalleOffer';


export const BrokerScreen = () => {
    const [curOption, setCurOption] = useState(1)
    const [curOffer, setCurOffer] = useState(null)
    const [curOrder, setCurOrder] = useState(null)
    const { broker, directorio, ofertas, directorio_ini, equipo, ordenes, matches_ofr, matches_ord } = useSelector(state => state.appEnv.appEnv);
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(startLogout())
    }
    return (
        curOption <= 6 ? (
            <Profile broker={broker} directorio={directorio} ofertas={ofertas} directorio_ini={directorio_ini} equipo={equipo} ordenes={ordenes} handleLogout={handleLogout} curOption={curOption} setCurOption={setCurOption} setCurOffer={setCurOffer} setCurOrder={setCurOrder} matches_ofr={matches_ofr} matches_ord={matches_ord} />
        ) : curOption === 7 ? (
            <CreateOffer broker={broker} curOption={curOption} setCurOption={setCurOption} />
        ) : curOption === 8 ? (
            <UpdateOffer broker={broker} curOffer={curOffer} curOption={curOption} setCurOption={setCurOption} />
        ) : curOption === 9 ? (
            <CreateOrder broker={broker} curOption={curOption} setCurOption={setCurOption} />
        ) : curOption === 10 ? (
            <ShowDetalleOffer broker={broker} curOffer={curOffer} curOption={curOption} setCurOption={setCurOption} />
        ) : (
            <ShowDetalleOrder broker={broker} curOffer={curOffer} curOption={curOption} setCurOption={setCurOption} />
        )
    )
}
