import React from 'react';
import { Provider } from 'react-redux';

import store, { persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRouter } from './routers/AppRouter'


export const BrokerHood = () => {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <AppRouter />
            </PersistGate>
        </Provider>
    )
}
