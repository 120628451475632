import React, { useState } from 'react'
import avatarDfl from '../assets/img/avatar-1.png';

const ProfileDetails = ({ curBroker, team, handleBrokerChg }) => {
    const profile = {
        name: curBroker.brk_name,
        imageUrl:
            curBroker.brk_avatar,
        coverImageUrl:
            'https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
        about: curBroker.brk_about ? curBroker.brk_about + ' ' + curBroker.brk_hits + ' Ventas Cerradas, ' + curBroker.brk_catches + ' Compras Realizadas' : '... Gran Colega, ' + curBroker.brk_hits + ' Ventas Cerradas, ' + curBroker.brk_catches + ' Compras Realizadas',
        fields: {
            Teléfono: curBroker.brk_telefono,
            Correo: curBroker.brk_mail,
            Cargo: curBroker.brk_cargo,
            Empresa: curBroker.brk_company,
            Ciudad: curBroker.brk_ciudad,
            Cumpleaños: curBroker.brk_birthday ? curBroker.brk_birthday : 'Muy Pronto',
        },
    }
    return (
        <div>
            <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    {Object.keys(profile.fields).map((field) => (
                        <div key={field} className="sm:col-span-1">
                            <dt className="text-sm font-medium text-purple-700">{field}</dt>
                            <dd className="mt-1 text-sm text-gray-900">{profile.fields[field]}</dd>
                        </div>
                    ))}
                    <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-purple-700">Que los colegas sepan de ti!</dt>
                        <dd
                            className="mt-1 max-w-full text-sm text-gray-900 space-y-5"
                            dangerouslySetInnerHTML={{ __html: profile.about }}
                        />
                    </div>
                </dl>
            </div>

            {/* Team member list */}
            <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
                <h2 className="text-sm font-medium text-purple-700">Equipo de Trabajo</h2>
                <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {team.map((person) => (
                        <div
                            key={person.id}
                            className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500"
                            onClick={() => handleBrokerChg(6, person)}
                        >
                            <div className="flex-shrink-0">
                                {person.brk_avatar
                                    ? <img className="h-12 w-12 rounded-full border-2 border-solid border-purple-200" src={person.brk_avatar} alt="" />
                                    : <img className="h-12 w-12 rounded-full" src={avatarDfl} alt="" />
                                }
                            </div>
                            <div className="flex-1 min-w-0">
                                <a href="#" className="focus:outline-none">
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    <p className="text-sm font-medium text-gray-900">{person.brk_name}</p>
                                    {person.brk_is_parent === 1
                                        ?
                                        (<p className="text-sm text-green-700 font-semibold truncate">{person.brk_cargo + ' (BU - Leader)'}</p>)
                                        :
                                        (<p className="text-sm text-gray-500 truncate">{person.brk_cargo}</p>)
                                    }

                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ProfileDetails





