import { types } from '../types/types';

export const initEnviroment = (datosEnv) => ({
    type: types.envLoad,
    payload: datosEnv
})

export const outEnviroment = () => ({
    type: types.envLogout,
    payload: {}
})
