export const AVATAR_DEFAULT =
  "https://brokerhood.com/files/brokerhood/avatar/avatar-1.png";
export const URL_AVATAR = "https://brokerhood.com/files/brokerhood/avatar/";
export const URL_OFFERT = "https://brokerhood.com/files/brokerhood/estates/";
export const URL_SERVICE = "https://brokerhood.com/api";
//export const URL_SERVICE = "https://brokerhood.com/dev";
export const URL_SHARE_OFFERT = "https://oferta.brokerhood.com/";
export const URL_SHARE_INVITE = "https://brokerhood.com/";
export const VERSION_APP = "1.0.8";
export const COLOR_PRM = '#6A3E98';
export const COLOR_SEC = "#66C6F1";
