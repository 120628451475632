import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
import {
    AtSymbolIcon,
    LockClosedIcon
} from "@heroicons/react/outline";
import logo from '../../assets/img/logo-4.png'
//import { Link } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import { startGoogleLogin, startLoginEmailPassword } from '../../actions/auth';
import { showNotification } from '../../utilities/ShowNotifications';

export const LoginScreen = () => {

    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.ui);

    const [formValues, handleInputChange] = useForm({
        email: '',
        password: ''
    })

    const { email, password } = formValues;

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(startLoginEmailPassword(email, password));
    }

    const handleGoogleLogin = () => {
        showNotification(
            "topLeft",
            1,
            "Inicio de Sesion con GOOGLE",
            "Hey COLEGA!! ... Esta opción pronto estará disponible..."
        );
        //dispatch(startGoogleLogin());
    }

    return (
        <div className="flex flex-col h-screen bg-[url('/src/assets/img/bg-3.jpg')]">
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="h-36 w-auto m-auto"
                        src={logo}
                        alt="Workflow"
                    />
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow shadow-purple-200 sm:rounded-lg sm:px-10">
                        <form onSubmit={handleLogin} className="space-y-6" action="#" method="POST">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Direccion de correo
                                </label>
                                <div className="mt-1">
                                    <Input placeholder='myuser@email.com' name='email' onChange={handleInputChange} value={email} maxLength={60} prefix={<AtSymbolIcon className='h-6 text-gray-400' />} />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Contraseña
                                </label>
                                <div className="mt-1">
                                    <Input.Password placeholder='my-secure-password' name='password' onChange={handleInputChange} value={password} prefix={<LockClosedIcon className='h-6 text-gray-400' />} />
                                </div>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-900 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                >
                                    Iniciar sesión
                                </button>
                            </div>

                            <div className="flex items-center justify-end">
                                <div className="text-sm">
                                    <p className="font-medium text-purple-800 hover:text-purple-500">
                                        Olvidé mi contraseña?
                                    </p>
                                </div>
                            </div>
                        </form>
                        <div className="mt-6">
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-purple-300" />
                                </div>
                                <div className="relative flex justify-center text-sm">
                                    <span className="px-2 bg-white text-gray-500"> O continue con </span>
                                </div>
                            </div>

                            <div className="mt-6 grid grid-cols-1 gap-3">
                                <div>
                                    <button
                                        type="button"
                                        disabled={loading}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-blue-600  bg-slate-50 hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                        onClick={handleGoogleLogin}
                                    >
                                        <img className="w-5 mr-2" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" alt="google button" />
                                        Google
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
