import { showNotification } from "../utilities/ShowNotifications";
const URL_SERVICE = "https://brokerhood.com/api";

class SRV {
    getBroker(user) {
        return fetch(`${URL_SERVICE}/6`, {
            method: "POST",
            body: JSON.stringify({
                id_mobile: user,
                app_origen: 2,
                app_version: 2.7,
            }),
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((res) => res.json())
            .catch((error) =>
                showNotification("topRight", 2, "Error Inesperado", error)
            )
            .then((response) => response);
    }

    sendOferta(record) {
        let uploadData = new FormData();
        uploadData.append("record", JSON.stringify(record));
        const headers = new Headers();
        headers.append("accept", "application/json");
        return fetch(`${URL_SERVICE}/141`, {
            method: "POST",
            body: uploadData,
            headers,
        })
            .then((res) => res.json())
            .catch((error) =>
                showNotification("topRight", 2, "Error Inesperado", error)
            )
            .then((response) => response);
    }

    updateOferta(record) {
        let uploadData = new FormData();
        uploadData.append("record", JSON.stringify(record));
        const headers = new Headers();
        headers.append("accept", "application/json");
        return fetch(`${URL_SERVICE}/142`, {
            method: "POST",
            body: uploadData,
            headers,
        })
            .then((res) => res.json())
            .catch((error) =>
                showNotification("topRight", 2, "Error Inesperado", error)
            )
            .then((response) => response);
    }

    sendOrder(record) {
        let uploadData = new FormData();
        uploadData.append("record", JSON.stringify(record));
        const headers = new Headers();
        headers.append("accept", "application/json");
        return fetch(`${URL_SERVICE}/143`, {
            method: "POST",
            body: uploadData,
            headers,
        })
            .then((res) => res.json())
            .catch((error) =>
                showNotification("topRight", 2, "Error Inesperado", error)
            )
            .then((response) => response);
    }

    deleOferPed(record) {
        let uploadData = new FormData();
        uploadData.append("record", JSON.stringify(record));
        const headers = new Headers();
        headers.append("accept", "application/json");
        return fetch(`${URL_SERVICE}/210`, {
            method: "POST",
            body: uploadData,
            headers,
        })
            .then((res) => res.json())
            .catch((error) =>
                showNotification("topRight", 2, "Error Inesperado", error)
            )
            .then((response) => response);
    }

    updateMatch(record) {
        let uploadData = new FormData();
        uploadData.append("record", JSON.stringify(record));
        const headers = new Headers();
        headers.append("accept", "application/json");
        return fetch(`${URL_SERVICE}/201`, {
            method: "POST",
            body: uploadData,
            headers,
        })
            .then((res) => res.json())
            .catch((error) =>
                showNotification("topRight", 2, "Error Inesperado", error)
            )
            .then((response) => response);
    }
}

export default new SRV();