import { Button, Popconfirm } from 'antd';
import Icon from "@mdi/react";
import {
    mdiShareVariantOutline,
    mdiArrowCollapseAll,
} from "@mdi/js";
import { myNumber } from '../utilities/ArrayFunctions';
import SRV from '../helpers/Service';
import { showNotification } from "../utilities/ShowNotifications";


function OrderCard(props) {
    const { order, setCurOrder, setCurOption, showMessage } = props;

    const deleteCurOrder = async (pedido) => {
        let record = {
            estado: 2,
            id: pedido.id,
            brk_id: pedido.brk_id
        };
        let res_oferta = await SRV.deleOferPed(record);
        if (res_oferta.type && Number.parseInt(res_oferta.type) > 0) {
            showNotification(
                "topRight",
                4,
                "ELIMINAR PEDIDO",
                "Proceso de eliminacion EXITOSO"
            );
        } else {
            showNotification(
                "topRight",
                2,
                "ELIMINAR PEDIDO",
                res_oferta.message
            );
        }
        setCurOption(1);
    };

    return (
        <div className="flex py-7 bg-white px-6 border-b cursor-pointer hover:opacity-80 hover:shadow-lg hover:shadow-purple-200 transition duration-200 ease-out">
            <div className="flex flex-col flex-grow pl-5">
                <div className="flex justify-between">
                    <p className="hidden text-gray-500 text-lg lg:block">{'Nº ' + order.id + ' - ' + order.mtv_nombre}</p>
                    <div className="flex">
                        <p className="text-lg lg:text-2xl mr-4 font-semibold mb-0">{'Entre ' + myNumber(1, order.ord_desde, 2) + ' y ' + myNumber(1, order.ord_hasta, 2)}</p>
                        <Icon path={mdiShareVariantOutline} size={"30px"} color="purple" onClick={() => showMessage('COMPARTIR EL PEDIDO')} />
                    </div>
                </div>
                <h4 className="text-xl">{order.tin_nombre + ' ' + order.ord_address}</h4>
                <h5 className="text-lg">{order.place_describe}</h5>
                <h5 className="text-lg">{'Area: ' + myNumber(1, order.ord_area, 2) + 'm2 - Lote: ' + myNumber(1, order.ord_lote, 2) + 'm2 - ' + myNumber(1, order.ord_alcobas, 2) + ' Alcobas - ' + myNumber(1, order.ord_banos, 2) + ' Baños - ' + myNumber(1, order.ord_parking, 2) + ' Parqueaderos - ' + myNumber(1, order.ord_elevadores, 2) + ' Ascensores'}</h5>
                <h5 className="text-lg">{'Requerimientos Adicionales: '} {order.ord_servicio === 1 ? 'Cuarto de Servicio - ' : ''} {order.ord_medios === 1 ? 'Medios baños - ' : ''} {order.ord_balcon === 1 ? 'Balcón' : ''}</h5>
                <div className="border-b w-10 pt-2" />
                <p className="text-purple-800 text-sm lg:hidden">{order.mtv_nombre}</p>
                <p className="pt-2 text-sm text-gray-500 flex-grow">{order.ord_nota}</p>
                <nav className="hidden lg:flex -mb-px justify-between space-between" aria-label="Buttons">
                    <span className="flex flex-row space-between">
                        <p className="text-lg lg:text-2xl mr-4 font-semibold mb-0">{order.matches}</p>
                        <Icon path={mdiArrowCollapseAll} size={"30px"} color="purple" onClick={() => showMessage('VER COINCIDENCIAS')} />
                    </span>
                    <span>
                        <Popconfirm
                            placement="rightBottom"
                            title="ELIMINAR EL PEDIDO?"
                            onConfirm={() => deleteCurOrder(order)}
                            okText="Si"
                            cancelText="No"
                            okType='default'
                        >
                            <Button type="text" style={{ color: '#6b21a8' }}>Eliminar</Button>
                        </Popconfirm>
                    </span>
                </nav>
            </div>
        </div>
    );
}

export default OrderCard;