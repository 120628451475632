import { Button, Popconfirm } from 'antd';
import Icon from "@mdi/react";
import {
    mdiShareVariantOutline,
    mdiArrowCollapseAll,
} from "@mdi/js";
import { myNumber } from '../utilities/ArrayFunctions';
import { URL_OFFERT } from "../utilities/Constants";
import SRV from '../helpers/Service';
import noFile from '../assets/img/logo-5.png';
import { showNotification } from "../utilities/ShowNotifications";

function OfferCard(props) {
    const { offer, setCurOffer, setCurOption, showMessage } = props;
    const url_photo =
        Number.parseInt(offer.ofr_tipo) === 1 ? URL_OFFERT + offer.ofr_image1 : offer.ofr_image1;

    const updateCurOffer = (record) => {
        setCurOffer(record);
        setCurOption(8);
    };

    const deleteCurOffer = async (oferta) => {
        let record = {
            estado: 1,
            id: oferta.id,
            brk_id: oferta.brk_id
        };
        let res_oferta = await SRV.deleOferPed(record);
        if (res_oferta.type && Number.parseInt(res_oferta.type) > 0) {
            showNotification(
                "topRight",
                4,
                "ELIMINAR OFERTA",
                "Proceso de eliminacion EXITOSO"
            );
        } else {
            showNotification(
                "topRight",
                2,
                "ELIMINAR OFERTA",
                res_oferta.message
            );
        }
        setCurOption(1);
    };

    return (
        <div className="flex py-7 bg-white px-6 border-b cursor-pointer hover:opacity-80 hover:shadow-lg hover:shadow-purple-200 transition duration-200 ease-out">
            <div className="relative h-24 w-40 md:h-44 md:w-64 flex-shrink-0">
                {offer.ofr_image1 !== 'ofr_default.jpg' ? (<img
                    src={url_photo}
                    alt='Imagen Principal'
                    className="w-full h-full object-center object-cover rounded-2xl"
                />) : <img
                    src={noFile}
                    alt='Imagen Principal'
                    className="w-full h-full object-center object-cover rounded-2xl"
                />}
            </div>
            <div className="flex flex-col flex-grow pl-5">
                <div className="flex justify-between">
                    <p className="hidden text-gray-500 text-lg lg:block">{'Nº ' + offer.id + ' - ' + offer.mtv_nombre}</p>
                    <div className="flex">
                        <p className="text-lg lg:text-2xl mr-4 font-semibold mb-0">{myNumber(1, offer.ofr_precio, 2)}</p>
                        <Icon path={mdiShareVariantOutline} size={"30px"} color="purple" onClick={() => showMessage('COMPARTIR LA OFERTA')} />
                    </div>
                </div>
                <h4 className="text-xl">{offer.tin_nombre + ' ' + offer.ofr_address}</h4>
                <h5 className="text-lg">{offer.place_describe}</h5>
                <h5 className="text-lg">{'Area: ' + myNumber(1, offer.ofr_area, 2) + 'm2 - Lote: ' + myNumber(1, offer.ofr_lote, 2) + 'm2 - ' + myNumber(1, offer.ofr_alcobas, 2) + ' Alcobas - ' + myNumber(1, offer.ofr_banos, 2) + ' Baños - ' + myNumber(1, offer.ofr_parking, 2) + ' Parqueaderos - ' + myNumber(1, offer.ofr_elevadores, 2) + ' Ascensores'}</h5>
                <h5 className="text-lg">{'Datos Adicionales: '} {offer.ofr_servicio === 1 ? 'Cuarto de Servicio - ' : ''} {offer.ofr_medios === 1 ? 'Medios baños - ' : ''} {offer.ofr_balcon === 1 ? 'Balcón' : ''}</h5>
                <div className="border-b w-10 pt-2" />
                <p className="text-purple-800 text-sm lg:hidden">{offer.mtv_nombre}</p>
                <p className="pt-2 text-sm text-gray-500 flex-grow">{offer.ofr_nota}</p>
                <nav className="hidden lg:flex -mb-px justify-between space-between" aria-label="Buttons">
                    <span className="flex flex-row space-between">
                        <p className="text-lg lg:text-2xl mr-4 font-semibold mb-0">{offer.matches}</p>
                        <Icon path={mdiArrowCollapseAll} size={"30px"} color="purple" onClick={() => showMessage('VER COINCIDENCIAS')} />
                    </span>
                    <span>
                        <Button type="text" onClick={() => updateCurOffer(offer)} style={{ color: '#6b21a8' }}>Actualizar</Button>
                        <Popconfirm
                            placement="rightBottom"
                            title="ELIMINAR LA OFERTA?"
                            onConfirm={() => deleteCurOffer(offer)}
                            okText="Si"
                            cancelText="No"
                            okType='default'
                        >
                            <Button type="text" style={{ color: '#6b21a8' }}>Eliminar</Button>
                        </Popconfirm>
                    </span>
                </nav>
            </div>
        </div>
    );
}

export default OfferCard;