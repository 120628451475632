import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLogout } from '../../actions/auth';

import CreateOffer from '../CreateOffer';


export const CreateOfferScreen = () => {
    const [curOption, setCurOption] = useState(1)
    const { broker } = useSelector(state => state.appEnv.appEnv);

    return (

        <CreateOffer broker={broker} curOption={curOption} setCurOption={setCurOption} />

    )
}
