import { Done } from "@mui/icons-material";

//Funcion para dar formato a la moneda
export const myNumber = (tipo, valor, dec = 2) => {
    return tipo === 1
        ? Intl.NumberFormat("en-US").format(Number.parseFloat(valor).toFixed(dec))
        : Intl.NumberFormat("en-US").format(Number.parseInt(valor));
};

export const getMyTeam = (idBroker, isParent, parentBrk, arrayBroker) => {
    if (Number.parseInt(isParent) === 1) {
        const newTeam = arrayBroker.filter((agent) => {
            return Number.parseInt(agent.brk_parent) === Number.parseInt(idBroker);
        });
        return newTeam;
    } else {
        const newTeam = arrayBroker.filter((agent) => {
            return Number.parseInt(agent.id) === Number.parseInt(parentBrk) || (Number.parseInt(agent.brk_parent) === Number.parseInt(parentBrk) && Number.parseInt(agent.id) !== Number.parseInt(idBroker));
        });
        return newTeam
    }
}


//Convierte la imagen en base 64
export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

export const arrayImage = (record, ruta) => {
    const result = [];
    const imageOfr = ruta + record.ofr_image1;
    let objImg = {
        id: 0,
        source: imageOfr
    }
    result.push(objImg);
    if (record.ofr_image2) {
        let objImg2 = {
            id: 1,
            source: ruta + record.ofr_image2
        }
        result.push(objImg2);
    }
    if (record.ofr_image3) {
        let objImg3 = {
            id: 2,
            source: ruta + record.ofr_image3
        }
        result.push(objImg3);
    }
    if (record.ofr_image4) {
        let objImg4 = {
            id: 3,
            source: ruta + record.ofr_image4
        }
        result.push(objImg4);
    }
    if (record.ofr_image5) {
        let objImg5 = {
            id: 4,
            source: ruta + record.ofr_image5
        }
        result.push(objImg5);
    }
    if (record.ofr_image6) {
        let objImg6 = {
            id: 5,
            source: ruta + record.ofr_image6
        }
        result.push(objImg6);
    }
    if (record.ofr_image7) {
        let objImg7 = {
            id: 6,
            source: ruta + record.ofr_image7
        }
        result.push(objImg7);
    }
    if (record.ofr_image8) {
        let objImg8 = {
            id: 7,
            source: ruta + record.ofr_image8
        }
        result.push(objImg8);
    }
    if (record.ofr_image9) {
        let objImg9 = {
            id: 8,
            source: ruta + record.ofr_image9
        }
        result.push(objImg9);
    }
    if (record.ofr_image10) {
        let objImg10 = {
            id: 9,
            source: ruta + record.ofr_image10
        }
        result.push(objImg10);
    }
    return result;
}

export const arrayImagesEdit = async (record, ruta) => {
    const result = [];
    const imageOfr = ruta + record.ofr_image1;
    let objImg = {
        id: 0,
        name: record.ofr_image1,
        status: 'done',
        url: imageOfr
    }
    result.push(objImg);
    if (record.ofr_image2) {
        let objImg2 = {
            id: 1,
            name: record.ofr_image2,
            status: 'done',
            url: ruta + record.ofr_image2
        }
        result.push(objImg2);
    }
    if (record.ofr_image3) {
        let objImg3 = {
            id: 2,
            name: record.ofr_image3,
            status: 'done',
            url: ruta + record.ofr_image3
        }
        result.push(objImg3);
    }
    if (record.ofr_image4) {
        let objImg4 = {
            id: 3,
            name: record.ofr_image4,
            status: 'done',
            url: ruta + record.ofr_image4
        }
        result.push(objImg4);
    }
    if (record.ofr_image5) {
        let objImg5 = {
            id: 4,
            name: record.ofr_image5,
            status: 'done',
            url: ruta + record.ofr_image5
        }
        result.push(objImg5);
    }
    if (record.ofr_image6) {
        let objImg6 = {
            id: 5,
            name: record.ofr_image6,
            status: 'done',
            url: ruta + record.ofr_image6
        }
        result.push(objImg6);
    }
    if (record.ofr_image7) {
        let objImg7 = {
            id: 6,
            name: record.ofr_image7,
            status: 'done',
            url: ruta + record.ofr_image7
        }
        result.push(objImg7);
    }
    if (record.ofr_image8) {
        let objImg8 = {
            id: 7,
            name: record.ofr_image8,
            status: 'done',
            url: ruta + record.ofr_image8
        }
        result.push(objImg8);
    }
    if (record.ofr_image9) {
        let objImg9 = {
            id: 8,
            name: record.ofr_image9,
            status: 'done',
            url: ruta + record.ofr_image9
        }
        result.push(objImg9);
    }
    if (record.ofr_image10) {
        let objImg10 = {
            id: 9,
            name: record.ofr_image10,
            status: 'done',
            url: ruta + record.ofr_image10
        }
        result.push(objImg10);
    }
    return result;
}

//Validar la existencia del producto en el carrito
export const validaTicketDet = (value, array) => {
    let count = 0;
    array.forEach((arrayValue) => {
        if (Number.parseInt(arrayValue.prd_id) === Number.parseInt(value)) {
            count += arrayValue.cantidad;
        }
    });
    return count > 0 ? count : 0;
};

//Actualizar la cantidad del producto en carrito
export const updateTicketDet = (value, array, tipo, newQty) => {
    const newDet = [];
    array.forEach((row) => {
        if (Number.parseInt(row.prd_id) === Number.parseInt(value)) {
            let item = {
                id: row.id,
                prd_id: row.prd_id,
                codigo: row.codigo,
                descripcion: row.descripcion,
                cantidad:
                    tipo === 1
                        ? Number.parseInt(row.cantidad) + 1
                        : tipo === 2
                            ? Number.parseInt(row.cantidad) - 1
                            : Number.parseInt(newQty),
                disponible: row.disponible,
                costo: row.costo,
                precioz: row.precioz,
                preciog: row.preciog,
                preciom: row.preciom,
                precio: row.precio,
                estado: row.estado,
            };
            newDet.push(item);
        } else {
            newDet.push(row);
        }
    });
    return newDet;
};

//Actualizar los precios del carrito a una lista
export const updateTicketDetLista = (value, array) => {
    const newDet = [];
    array.forEach((row) => {
        let item = {
            id: row.id,
            prd_id: row.prd_id,
            codigo: row.codigo,
            descripcion: row.descripcion,
            disponible: row.disponible,
            cantidad: row.cantidad,
            costo: row.costo,
            precioz: row.precioz,
            preciog: row.preciog,
            preciom: row.preciom,
            precio: value === 1 ? row.preciog : row.preciom,
            estado: row.estado,
        };
        newDet.push(item);
    });
    return newDet;
};

//Actualizar el precio del producto en carrito
export const updateTicketDetPrecio = (value, array, tipo, newPrice) => {
    const newDet = [];
    array.forEach((row) => {
        if (Number.parseInt(row.prd_id) === Number.parseInt(value)) {
            let item = {
                id: row.id,
                prd_id: row.prd_id,
                codigo: row.codigo,
                descripcion: row.descripcion,
                cantidad: Number.parseInt(row.cantidad),
                disponible: row.disponible,
                costo: row.costo,
                precioz: row.precioz,
                preciog: row.preciog,
                preciom: row.preciom,
                precio: Number.parseFloat(newPrice),
                estado: row.estado,
            };
            newDet.push(item);
        } else {
            newDet.push(row);
        }
    });
    return newDet;
};

//Eliminar un item del carrito
export const deleteTicketDet = (value, array) => {
    const newDet = [];
    array.forEach((row) => {
        if (Number.parseInt(row.prd_id) !== Number.parseInt(value)) {
            let item = {
                id: row.id,
                prd_id: row.prd_id,
                codigo: row.codigo,
                descripcion: row.descripcion,
                cantidad: Number.parseInt(row.cantidad),
                disponible: row.disponible,
                costo: row.costo,
                precioz: row.precioz,
                preciog: row.preciog,
                preciom: row.preciom,
                precio: row.precio,
                estado: row.estado,
            };
            newDet.push(item);
        }
    });
    return newDet;
};

//Funcion para totalizar el carrito
//Eliminar un item del carrito
export const totalTicketDet = (array) => {
    let totalDet = 0;
    array.forEach((row) => {
        totalDet =
            totalDet + Number.parseInt(row.cantidad) * Number.parseFloat(row.precio);
    });
    return Number.parseFloat(totalDet).toFixed(2);
};

//Funcion para agregar detalle a TMP
export const addTicketTempDet = (curTmpDet, array) => {
    const newDet = curTmpDet;
    array.forEach((row) => {
        let item = {
            id: row.id,
            prd_id: row.prd_id,
            codigo: row.codigo,
            descripcion: row.descripcion,
            cantidad: Number.parseInt(row.cantidad),
            disponible: row.disponible,
            costo: row.costo,
            precioz: row.precioz,
            preciog: row.preciog,
            preciom: row.preciom,
            precio: row.precio,
            estado: row.estado,
        };
        newDet.push(item);
    });
    return newDet;
};

//Funcion para extraer detalle de TMP
export const reloadTicketTmpDet = (value, array) => {
    const newDet = [];
    array.forEach((row) => {
        if (row.id === value) {
            let item = {
                id: row.id,
                prd_id: row.prd_id,
                codigo: row.codigo,
                descripcion: row.descripcion,
                cantidad: Number.parseInt(row.cantidad),
                disponible: row.disponible,
                costo: row.costo,
                precioz: row.precioz,
                preciog: row.preciog,
                preciom: row.preciom,
                precio: row.precio,
                estado: row.estado,
            };
            newDet.push(item);
        }
    });
    return newDet;
};

//Funcion para eliminar encabezado de TMP
export const removeHeaderTmp = (array, id) => {
    const headersTemp = array.filter((tikets) => {
        return tikets.id !== id;
    });
    return headersTemp;
};

//Funcion para Eliminar un detalle de TMP
export const deleteTicketTmpDet = (value, array) => {
    const newDet = [];
    array.forEach((row) => {
        if (row.id !== value) {
            let item = {
                id: row.id,
                prd_id: row.prd_id,
                codigo: row.codigo,
                descripcion: row.descripcion,
                cantidad: Number.parseInt(row.cantidad),
                disponible: row.disponible,
                costo: row.costo,
                precioz: row.precioz,
                preciog: row.preciog,
                preciom: row.preciom,
                precio: row.precio,
                estado: row.estado,
            };
            newDet.push(item);
        }
    });
    return newDet;
};

//Suma ventas y descartes array
export const getGestionArray = (array, tipo) => {
    let monto_venta = 0;
    let monto_cobro = 0;
    let monto_cambio = 0;
    let plan = 0;
    array.forEach((row) => {
        monto_venta += Number.parseFloat(row.totalTicket);
        monto_cobro += tipo === 1 ? Number.parseFloat(row.cobro) : 0;
        monto_cambio += tipo === 1 ? Number.parseFloat(row.cambio) : 0;
        plan += tipo === 1 ? (row.planSepare ? 1 : 0) : 0;
    });
    let resumen = {
        ventas: monto_venta,
        cobros: monto_cobro - monto_cambio,
        planes: plan,
    };
    return resumen;
};

//Suma ventas array
export const getGestionEfectivo = (array) => {
    let monto_entra = 0;
    let monto_sale = 0;
    array.forEach((row) => {
        monto_entra += row.tipo === 1 ? Number.parseFloat(row.valor) : 0;
        monto_sale += row.tipo === 1 ? 0 : Number.parseFloat(row.valor);
    });
    let resumen = {
        ingresos: monto_entra,
        egresos: monto_sale,
    };
    return resumen;
};

//Suma ventas array
export const getResumenTurno = (ventas, efectivo, base, resushift) => {
    //Calculo de efectivo
    let monto_entra = 0;
    let monto_sale = 0;
    efectivo.forEach((row) => {
        monto_entra += row.tipo === 1 ? Number.parseFloat(row.valor) : 0;
        monto_sale += row.tipo === 1 ? 0 : Number.parseFloat(row.valor);
    });

    //Calculo de ventas
    let monto_venta = 0;
    let monto_cobro = 0;
    let monto_cambio = 0;
    let plan = 0;
    /*ventas.forEach((row) => {
      monto_venta += Number.parseFloat(row.totalTicket);
      monto_cobro += Number.parseFloat(row.cobro);
      monto_cambio += Number.parseFloat(row.cambio);
      plan += row.planSepare ? 1 : 0;
    });*/

    let val_cobro = monto_cobro - monto_cambio;

    /*let resumen = {
      ingresos: monto_entra,
      egresos: monto_sale,
      ventas: monto_venta,
      cobros: monto_cobro - monto_cambio,
      planes: plan,
      saldo: Number.parseFloat(base + val_cobro + monto_entra - monto_sale),
    };*/
    let resumen = {
        ingresos: Number.parseFloat(monto_entra),
        egresos: Number.parseFloat(monto_sale),
        ventas: Number.parseFloat(resushift.total_venta),
        cobros: Number.parseFloat(resushift.total_cobro),
        num_tickets: Number.parseFloat(resushift.numero_tickets),
        planes: plan,
        saldo: Number.parseFloat(
            Number.parseFloat(base) +
            Number.parseFloat(resushift.total_cobro) +
            Number.parseFloat(monto_entra) -
            Number.parseFloat(monto_sale)
        ),
    };

    return resumen;
};

//Actualizar el estado del ticket
export const updateEstadoTicket = (value, array) => {
    const newDef = [];
    array.forEach((row) => {
        if (row.id === value) {
            const newVta = {
                id: row.id,
                fecha: row.fecha,
                turno: row.turno,
                user: row.user,
                nombre: row.nombre,
                cliente: row.cliente,
                clt_id: row.clt_id,
                totalTicket: row.totalTicket,
                estado: 1,
                lista: row.lista,
                fecha_pago: row.fecha_pago,
                nota: row.nota,
                cobro: row.cobro,
                cambio: row.cambio,
                planSepare: row.planSepare,
                newCliente: row.newCliente,
                telCliente: row.telCliente,
                ip_ticket: row.ip_ticket,
                descuento: row.descuento,
            };
            newDef.push(newVta);
        } else {
            newDef.push(row);
        }
    });
    return newDef;
};

//Actualizar el estado del detalle del ticket
export const updateEstadoTicketDet = (array) => {
    const newDet = [];
    array.forEach((row) => {
        const newItem = {
            id: row.id,
            prd_id: row.prd_id,
            codigo: row.codigo,
            descripcion: row.descripcion,
            cantidad: row.cantidad,
            disponible: row.disponible,
            costo: row.costo,
            precioz: row.precioz,
            preciog: row.preciog,
            preciom: row.preciom,
            precio: row.precio,
            estado: 1,
        };
        newDet.push(newItem);
    });
    return newDet;
};

//Actualizar el estado de la gestion de efectivo
export const updateEstadoEfectivo = (value, array) => {
    const efeDef = [];
    array.forEach((row) => {
        if (row.id === value) {
            const newEfe = {
                id: row.id,
                fecha: row.fecha,
                user: row.user,
                nombre: row.nombre,
                nota: row.nota,
                tipo: row.tipo,
                estado: 1,
                valor: row.valor,
            };
            efeDef.push(newEfe);
        } else {
            efeDef.push(row);
        }
    });
    return efeDef;
};

//Funcion para filtrar cualquier array
export const filtrarArray = (array, id, tipo, clase) => {
    if (clase === 1) {
        //Clientes
        const clientes = array.filter((row) => {
            if (tipo === 1) {
                return Number.parseInt(row.trc_id) !== Number.parseInt(id);
            } else {
                return Number.parseInt(row.trc_id) === Number.parseInt(id);
            }
        });
        return clientes;
    }
};

export const removeArrayDuplicates = (array) => {
    return Array.from(new Set(array));
};

export const nombreMes = (tipo, num_mes = 0) => {
    const fecha = new Date();
    let mes = num_mes === 0 ? fecha.getMonth() : num_mes;
    var meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];
    let newMes = meses[Number.parseInt(mes)];
    return tipo === 1 ? newMes.toUpperCase() : newMes.substr(0, 3);
};
