import { useState } from 'react';
import { useSelector } from 'react-redux';
import { LocationMarkerIcon } from '@heroicons/react/outline'
import {
    Input,
    InputNumber,
    Popconfirm,
    message,
    Button,
    Select,
} from "antd";
import ProfileHeader from './ProfileHeader'
import { myNumber } from '../utilities/ArrayFunctions';
import SRV from '../helpers/Service';
import { showNotification } from '../utilities/ShowNotifications';
import AutoComplete from './AutoComplete';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CreateOrder({ broker, curOption, setCurOption }) {
    const { zonas, tipos_inm } = useSelector(state => state.appEnv.appEnv);
    const [loadingButton, setLoadingButton] = useState(false);
    const [locActiva, setLocActiva] = useState(1)

    const [tipoInmuebleId, setTipoInmuebleId] = useState(0);
    const [zonaId, setZonaId] = useState(0);
    const [motivoId, setMotivoId] = useState(0);
    const [areaOferta, setAreaOferta] = useState(0);
    const [servicioOferta, setServicioOferta] = useState(0);
    const [balconOferta, setBalconOferta] = useState(0);
    const [unidadOferta, setUnidadOferta] = useState('');
    const [loteOferta, setLoteOferta] = useState(0);
    const [alcobasOferta, setAlcobasOferta] = useState(0);
    const [banosOferta, setBanosOferta] = useState(0);
    const [mediosOferta, setMediosOferta] = useState(0);
    const [elevadorOferta, setElevadorOferta] = useState(0);
    const [parkingOferta, setParkingOferta] = useState(0);
    const [notaOferta, setNotaOferta] = useState('');
    const [precioDesde, setPrecioDesde] = useState(0);
    const [precioHasta, setPrecioHasta] = useState(0);
    const [selectLocat, setSelectLocat] = useState({
        place: '',
        description: '',
        latitud: 0,
        longitud: 0
    })

    const { Option } = Select;
    const { TextArea } = Input;

    const selectTipoInmueble = (value) => {
        setTipoInmuebleId(value);
    };
    const selectZonaId = (value) => {
        setZonaId(value);
    };

    const selectMotivo = (value) => {
        setMotivoId(value);
    };

    const selectServicio = (value) => {
        setServicioOferta(value);
    };

    const selectMedio = (value) => {
        setMediosOferta(value);
    };

    const selectBalcon = (value) => {
        setBalconOferta(value);
    };

    const switchLocation = (tipo) => {
        setZonaId(0);
        setSelectLocat({
            place: '',
            description: '',
            latitud: 0,
            longitud: 0
        })
        setLocActiva(tipo);
    };

    const myFormat = (valor, tipo) => {
        if (Number.isNaN(Number.parseFloat(valor))) {
            message.error('Valor incorrecto');
            return false;
        }
        if (tipo === 1) {
            setPrecioDesde(valor);
        } else if (tipo === 2) {
            setAreaOferta(valor);
        } else if (tipo === 3) {
            setLoteOferta(valor);
        } else if (tipo === 4) {
            setAlcobasOferta(valor);
        } else if (tipo === 5) {
            setBanosOferta(valor);
        } else if (tipo === 6) {
            setParkingOferta(valor);
        } else if (tipo === 7) {
            setElevadorOferta(valor);
        } else {
            setPrecioHasta(valor);
        }
    }

    // ENVIO A WEBSERVICES
    const uploadOrder = async () => {
        if (
            (Number.parseInt(zonaId) === 0 && selectLocat.place.length === 0) ||
            Number.parseInt(tipoInmuebleId) === 0 ||
            Number.parseInt(motivoId) === 0 ||
            Number.parseFloat(areaOferta) === 0 ||
            Number.parseFloat(precioDesde) === 0 ||
            Number.parseFloat(precioHasta) === 0 ||
            notaOferta.length === 0
        ) {
            showNotification(
                "topRight",
                2,
                "REGISTRAR PEDIDO",
                "Ubicacion, Tipo de Inmueble, Motivo, Area, Precio y Descripcion son datos obligatorios... por favor verificar!"
            );
            return false;
        } else {
            if (Number.parseFloat(precioDesde) > Number.parseFloat(precioHasta)) {
                showNotification(
                    "topRight",
                    2,
                    "REGISTRAR PEDIDO",
                    "El precio dede no puede ser mayor que el precio hasta... por favor verificar!"
                );
                return false;
            } else {
                setLoadingButton(true);
                let record = {
                    brk_id: broker.id,
                    brk_parent: broker.brk_parent,
                    zna_id: zonaId,
                    tin_id: tipoInmuebleId,
                    mtv_id: motivoId,
                    ord_area: areaOferta,
                    ord_lote: loteOferta,
                    ord_desde: precioDesde,
                    ord_hasta: precioHasta,
                    ord_alcobas: alcobasOferta,
                    ord_servicio: servicioOferta,
                    ord_banos: banosOferta,
                    ord_medios: mediosOferta,
                    ord_balcon: balconOferta,
                    ord_address: unidadOferta,
                    ord_parking: parkingOferta,
                    ord_elevadores: elevadorOferta,
                    ord_nota: notaOferta,
                    placeId: selectLocat.place,
                    placeDescription: selectLocat.description,
                    ord_latitud: selectLocat.latitud,
                    ord_longitud: selectLocat.longitud,
                };
                let res_oferta = await SRV.sendOrder(record);
                if (res_oferta.type && Number.parseInt(res_oferta.type) > 0) {
                    //dispatch(reLoadEnviroment(res_oferta.datos));
                    if (Number.parseInt(res_oferta.matches) > 0) {
                        showNotification(
                            "topRight",
                            4,
                            "REGISTRAR PEDIDO",
                            "Se registró el PEDIDO y se encontraron COINCIDENCIAS"
                        );
                    } else {
                        showNotification(
                            "topRight",
                            4,
                            "REGISTRAR PEDIDO",
                            "Proceso de registro EXITOSO"
                        );
                    }
                } else {
                    showNotification(
                        "topRight",
                        2,
                        "REGISTRAR PEDIDO",
                        res_oferta.message
                    );
                }
            }

        }
        setLoadingButton(false);
        setCurOption(1);
    };

    return (
        <>
            <div>
                <ProfileHeader curBroker={broker} curOption={curOption} setCurOption={setCurOption} />
                <div className="p-5 bg-gray-100">
                    <div className="md:grid md:grid-cols-1 max-w-7xl mx-auto md:gap-6">
                        <div className="mt-5 bg-gray-100 md:mt-0 md:col-span-1">
                            <form action="#" method="POST">
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-8">
                                                <h1 className='mx-auto flex justify-center text-3xl text-purple-800'>Registrar Pedido</h1>
                                            </div>
                                            {locActiva === 1
                                                ?
                                                (<div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-8">
                                                    <label htmlFor="ubicacion-inmueble" className="block text-lg font-medium text-purple-700">
                                                        Ubicación del Inmueble
                                                    </label>
                                                    <div className="mt-1 col-span-3 flex rounded-md shadow-sm">
                                                        <Select
                                                            defaultValue={zonaId === 0 ? 'Seleccione Ubicacion' : zonaId}
                                                            style={{ width: '100%' }}
                                                            onChange={selectZonaId}
                                                            value={zonaId === 0 ? 'Seleccione Ubicacion' : zonaId}
                                                            name='zonaId'
                                                            showSearch
                                                            size='large'
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {zonas.map(zona => (
                                                                <Option key={zona.id}>{zona.descripcion}</Option>
                                                            ))}
                                                        </Select>
                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center p-2 ml-3 border border-blue-300 rounded-full shadow-sm text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                        >
                                                            <LocationMarkerIcon className="h-5 w-5 text-blue-500" aria-hidden="true" onClick={() => switchLocation(2)} />
                                                        </button>
                                                    </div>
                                                </div>
                                                ) : (
                                                    <div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-8">
                                                        <label htmlFor="ubicacion-inmueble" className="block text-lg font-medium text-purple-700">
                                                            Buscar en Google Place
                                                        </label>
                                                        <div className="mt-1 col-span-3 flex rounded-md shadow-sm">
                                                            <AutoComplete setSelectLocat={setSelectLocat} />
                                                            <button
                                                                type="button"
                                                                className="inline-flex items-center p-2 ml-3 border border-purple-300 rounded-full shadow-sm text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                                            >
                                                                <LocationMarkerIcon className="h-5 w-5 text-purple-600" aria-hidden="true" onClick={() => switchLocation(1)} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-4 sm:col-span-4 md:col-span-4 lg:col-span-4">
                                                <label htmlFor="unidad" className="block text-lg font-medium text-purple-600">
                                                    {'Precio Desde $' + myNumber(1, precioDesde, 2)}
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={precioDesde}
                                                        onChange={(value) => myFormat(value, 1)}
                                                        name='precioDesde'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-4 sm:col-span-4 md:col-span-4 lg:col-span-4">
                                                <label htmlFor="unidad" className="block text-lg font-medium text-purple-600">
                                                    {'Precio Hasta $' + myNumber(1, precioHasta, 2)}
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={precioHasta}
                                                        onChange={(value) => myFormat(value, 8)}
                                                        name='precioHasta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-8">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {'Punto de Referencia (Opcional)'}
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Input placeholder="Punto de Referencia" name='unidadOferta' value={unidadOferta} onChange={(e) => setUnidadOferta(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="tipo-inmueble" className="block text-base font-medium text-purple-600">
                                                    Tipo de Inmueble
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Select
                                                        defaultValue={tipoInmuebleId === 0 ? 'Seleccione tipo de Inmueble' : tipoInmuebleId}
                                                        style={{ width: '100%' }}
                                                        onChange={selectTipoInmueble}
                                                        value={tipoInmuebleId === 0 ? 'Seleccione el Tipo' : tipoInmuebleId}
                                                        showSearch
                                                        size='large'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {tipos_inm.map(tipo_inm => (
                                                            <Option key={tipo_inm.value}>{tipo_inm.label}</Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </div>

                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="motivo" className="block text-base font-medium text-purple-600">
                                                    Motivo del Pedido
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Select
                                                        defaultValue={motivoId === '0' ? 'Motivo del Pedido' : motivoId}
                                                        style={{ width: '100%' }}
                                                        onChange={selectMotivo}
                                                        value={motivoId}
                                                        name='motivoId'
                                                        showSearch
                                                        size='large'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value={0}>Seleccione Motivo</Option>
                                                        <Option value={1}>COMPRA</Option>
                                                        <Option value={2}>ALQUILER</Option>
                                                        <Option value={3}>PERMUTA</Option>
                                                        <Option value={4}>CUALQUIERA</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Area: {myNumber(1, areaOferta, 2)}m<sup>2</sup>
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={areaOferta}
                                                        onChange={(value) => myFormat(value, 2)}
                                                        name='areaOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%" }}
                                                        size='large'
                                                        formatter={(value) =>
                                                            `${value}`.replace(/\B(?=(\d{0})+(?!\d))/g, ",")
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Lote: {myNumber(1, loteOferta, 2)}m<sup>2</sup>
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={loteOferta}
                                                        onChange={(value) => myFormat(value, 3)}
                                                        name='loteOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {myNumber(1, alcobasOferta, 0)} Alcobas
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={alcobasOferta}
                                                        onChange={(value) => myFormat(value, 4)}
                                                        name='alcobasOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%", textAlign: "right" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Alcoba de Servicio
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Select
                                                        defaultValue={servicioOferta}
                                                        style={{ width: '100%' }}
                                                        onChange={selectServicio}
                                                        value={servicioOferta}
                                                        name='servicioOferta'
                                                        showSearch
                                                        size='large'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value={0}>NO</Option>
                                                        <Option value={1}>SI</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {myNumber(1, banosOferta, 0)} Baños
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={banosOferta}
                                                        onChange={(value) => myFormat(value, 5)}
                                                        name='banosOferta'
                                                        controls={false}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%", textAlign: "right" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Medios Baños
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Select
                                                        defaultValue={mediosOferta}
                                                        style={{ width: '100%' }}
                                                        onChange={selectMedio}
                                                        value={mediosOferta}
                                                        name='mediosOferta'
                                                        showSearch
                                                        size='large'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value={0}>NO</Option>
                                                        <Option value={1}>SI</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-2 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {myNumber(1, parkingOferta, 0)} Parqueaderos
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={parkingOferta}
                                                        onChange={(value) => myFormat(value, 6)}
                                                        name='parkingOferta'
                                                        controls={false}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%", textAlign: "right" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-2 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Balcon Requerido
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Select
                                                        defaultValue={balconOferta}
                                                        style={{ width: '100%' }}
                                                        onChange={selectBalcon}
                                                        value={balconOferta}
                                                        name='balconOferta'
                                                        showSearch
                                                        size='large'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value={0}>NO</Option>
                                                        <Option value={1}>SI</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-2 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {myNumber(1, elevadorOferta, 0)} Ascensonres
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={elevadorOferta}
                                                        onChange={(value) => myFormat(value, 7)}
                                                        name='elevadorOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%", textAlign: "right" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div>
                                            <label htmlFor="about" className="block text-base font-medium text-purple-600">
                                                Describe la Propiedad
                                            </label>
                                            <div className="mt-1">
                                                <TextArea rows={4} value={notaOferta} onChange={(e) => setNotaOferta(e.target.value)} name='notaOferta' placeholder="Breve descripcion de la propiedad... Máximo 250 caracteres" maxLength={250} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                        <Popconfirm
                                            placement="top"
                                            title="CONFIRMAR EL MOVIMIENTO?"
                                            onConfirm={() => uploadOrder()}
                                            okText="Si"
                                            cancelText="No"
                                            okType='default'
                                        >
                                            <Button
                                                type="primary"
                                                className='btnLila'
                                                shape="round"
                                                block
                                                loading={loadingButton}
                                                size='large'
                                            >
                                                REGISTRAR EL PEDIDO
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="hidden sm:block" aria-hidden="true">
                    <div className="py-5">
                        <div className="border-t border-gray-200" />
                    </div>
                </div>

            </div>

        </>
    )
}