import React from 'react'
import { MailIcon, PhoneIcon } from '@heroicons/react/solid'
import { UserCircleIcon, HomeIcon } from '@heroicons/react/outline'

import splash from '../assets/img/splash-5.jpg'
import splashBrk from '../assets/img/splash-2.jpg'
import avatarDfl from '../assets/img/avatar-1.png';

const ProfileHeader = ({ curBroker, curOption, setCurOption }) => {
    return (
        <div className={`${curOption < 7 ? 'bg-white' : 'bg-gray-100'
            }`}>
            <div>
                <img className="h-32 w-full object-cover lg:h-48" src={curOption === 6 ? splashBrk : splash} alt="" />
            </div>
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                    <div className="flex">
                        {curBroker.brk_avatar
                            ? <img className="h-24 w-24 rounded-full ring-4 ring-purple-200 sm:h-32 sm:w-32" src={curBroker.brk_avatar} alt="" />
                            : <img className="h-32 w-32 h rounded-full" src={avatarDfl} alt="" />
                        }
                    </div>
                    <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                        <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                            <h1 className="text-2xl font-bold text-gray-900 truncate">{curBroker.brk_name}</h1>
                        </div>
                        <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                            {curOption === 6
                                ?
                                (<>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                    >
                                        <MailIcon className="-ml-1 mr-2 h-5 w-5 text-purple-600" aria-hidden="true" />
                                        <span>Email</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                    >
                                        <PhoneIcon className="-ml-1 mr-2 h-5 w-5 text-purple-600" aria-hidden="true" />
                                        <span>Llamar</span>
                                    </button>
                                </>)
                                : curOption === 1 ?
                                    (<button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                    >
                                        <UserCircleIcon className="-ml-1 mr-2 h-5 w-5 text-purple-600" aria-hidden="true" />
                                        <span>Actualizar</span>
                                    </button>) : (
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                            onClick={() => setCurOption(1)}
                                        >
                                            <HomeIcon className="-ml-1 mr-2 h-5 w-5 text-purple-600" aria-hidden="true" />
                                            <span>Cancelar</span>
                                        </button>
                                    )
                            }

                        </div>
                    </div>
                </div>
                <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                    <h1 className="text-2xl font-bold text-gray-900 truncate">{curBroker.brk_name}</h1>
                </div>
            </div>
        </div>
    )
}

export default ProfileHeader
