import React, { useEffect, useState } from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import { useDispatch } from 'react-redux';

import { auth, onAuthStateChanged } from '../firebase/firebaseConfig';
import { startLoadingEnviroment } from '../actions/auth';

import { AuthRouter } from './AuthRouter';
import { BrokerScreen } from '../components/broker/BrokerScreen';
import { CreateOfferScreen } from '../components/broker/CreateOfferScreen';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';


export const AppRouter = () => {
    const dispatch = useDispatch();

    const [checking, setChecking] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(
        () =>
            onAuthStateChanged(auth, async (user) => {
                if (user?.uid) {
                    dispatch(startLoadingEnviroment(user.uid, user.displayName));
                    setIsLoggedIn(true);

                    //Inicia la carga de valores de sistema para el usuario
                    //dispatch(startLoadingNotes(user.uid));

                } else {
                    setIsLoggedIn(false);
                }
                setChecking(false);
            }),
        [dispatch, setChecking, setIsLoggedIn]
    );

    if (checking) {
        return (
            <h1>Espere...</h1>
        )
    }

    return (
        <Router>
            <div>
                <Routes>
                    <Route path='/auth/*' element={
                        <PublicRoute>
                            <AuthRouter />
                        </PublicRoute>
                    } />

                    <Route path='/' element={
                        <PrivateRoute>
                            <BrokerScreen />
                        </PrivateRoute>
                    } />

                    <Route path='/newoffer' element={
                        <PrivateRoute>
                            <CreateOfferScreen />
                        </PrivateRoute>
                    } />

                    <Route path='*' element={<Navigate replace to='/' />} />
                </Routes>
            </div>
        </Router>
    )
}
