import { Button } from 'antd';
import Icon from "@mdi/react";
import {
    mdiShareVariantOutline,
} from "@mdi/js";
import { myNumber } from '../utilities/ArrayFunctions';
import SRV from '../helpers/Service';
import avatarDfl from '../assets/img/avatar-1.png';
import { showNotification } from "../utilities/ShowNotifications";

function MatchOfferCard(props) {
    const { match, setCurOffer, setCurOption, showMessage } = props;

    const showDetaOffer = (record) => {
        setCurOffer(record);
        setCurOption(10);
    };

    return (
        <div className="flex py-7 bg-white px-6 border-b cursor-pointer hover:opacity-80 hover:shadow-lg hover:shadow-purple-200 transition duration-200 ease-out">
            <div className="relative items-center ml-6 h-12 w-12 md:h-12 md:w-20 flex-shrink-0">
                {match.ord_avatar
                    ? <img className="h-16 w-16 rounded-full border-2 border-solid border-purple-200" src={match.ord_avatar} alt="" />
                    : <img className="h-16 w-16 rounded-full" src={avatarDfl} alt="" />
                }
            </div>
            <div className="flex flex-col flex-grow pl-5">
                <div className="flex justify-between">
                    <p className="hidden text-gray-500 text-lg lg:block">{'Nº ' + match.id + ' del ' + match.cnc_date}</p>
                    <div className="flex">
                        <p className="text-lg lg:text-2xl mr-4 font-semibold mb-0">{myNumber(1, match.ofr_precio, 2)}</p>
                        <Icon path={mdiShareVariantOutline} size={"30px"} color="purple" onClick={() => showMessage('COMPARTIR LA OFERTA')} />
                    </div>
                </div>
                <h4 className="text-xl font-semibold">{match.solicitante + ' (' + match.company_solicitante + ')'}</h4>
                <h4 className="text-xl">{'E-mail: ' + match.mail_solicitante + ' Tel: ' + match.telefono_solicitante}</h4>
                <h5 className="text-lg">{'OFR Nº ' + match.ofr_id + ' - ' + match.tin_nombre + ' ' + match.ofr_place}</h5>
                <div className="border-b w-10 pt-2" />
                <p className="text-purple-800 text-sm lg:hidden">{'Nº ' + match.id + ' del ' + match.cnc_date}</p>
                <nav className="hidden lg:flex -mb-px justify-end" aria-label="Buttons">
                    <span>
                        <Button type="text" onClick={() => showDetaOffer(match)} style={{ color: '#6b21a8' }}>Detalles</Button>
                    </span>
                </nav>
            </div>
        </div>
    );
}

export default MatchOfferCard;