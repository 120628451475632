import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBbbqRlLKh58Gboy-73McYBqlQREL84xqU",
    authDomain: "brokerhood-fe2a6.firebaseapp.com",
    databaseURL: "https://brokerhood-fe2a6.firebaseio.com",
    projectId: "brokerhood-fe2a6",
    storageBucket: "brokerhood-fe2a6.appspot.com",
    messagingSenderId: "686257723652",
    appId: "1:686257723652:web:4a01e166a75dc34ea2d851",
    measurementId: "G-MKGFTFL9CJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();
const googleAuthProvider = new GoogleAuthProvider();

export { auth, db, googleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword, onAuthStateChanged, signOut };