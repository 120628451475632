import { act } from "@testing-library/react"
import { types } from "../types/types"

const initialState = {
    appEnv: null
}

export const envReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.envLoad:
            return {
                ...state,
                appEnv: {
                    ...action.payload
                }
            }
        case types.envLogout:
            return {
                ...state,
                appEnv: null
            }
        default:
            return state
    }
}