import { useEffect, useState } from 'react';
import { LocationMarkerIcon } from '@heroicons/react/outline'
import { PlusOutlined } from "@ant-design/icons";
import {
    Upload,
    Input,
    InputNumber,
    Popconfirm,
    message,
    Button,
    Select,
    Modal,
} from "antd";
import ProfileHeader from './ProfileHeader'
import { arrayImage, arrayImagesEdit, getBase64, myNumber } from '../utilities/ArrayFunctions';
import SRV from '../helpers/Service';
import { showNotification } from '../utilities/ShowNotifications';
import { URL_OFFERT } from '../utilities/Constants';
import SmallCard from './SmallCard';

export default function UpdateOffer({ broker, curOffer, curOption, setCurOption }) {
    const [loadingButton, setLoadingButton] = useState(false);
    const [imagesOffer, setImagesOffer] = useState([])

    useEffect(() => {
        const url_photo = Number.parseInt(curOffer.ofr_tipo) === 1 ? URL_OFFERT : "";
        (async () => {
            const arrayUrls = arrayImage(curOffer, url_photo);
            setImagesOffer(arrayUrls);
        })();
    }, [curOffer]);

    const [areaOferta, setAreaOferta] = useState(curOffer.ofr_area);
    const [servicioOferta, setServicioOferta] = useState(curOffer.ofr_servicio);
    const [balconOferta, setBalconOferta] = useState(curOffer.ofr_balcon);
    const [unidadOferta, setUnidadOferta] = useState(curOffer.ofr_address);
    const [loteOferta, setLoteOferta] = useState(curOffer.ofr_lote);
    const [alcobasOferta, setAlcobasOferta] = useState(curOffer.ofr_alcobas);
    const [banosOferta, setBanosOferta] = useState(curOffer.ofr_banos);
    const [mediosOferta, setMediosOferta] = useState(curOffer.ofr_medios);
    const [parkingOferta, setParkingOferta] = useState(curOffer.ofr_parking);
    const [elevadorOferta, setElevadorOferta] = useState(curOffer.ofr_elevadores);
    const [notaOferta, setNotaOferta] = useState(curOffer.ofr_nota);
    const [precioOferta, setPrecioOferta] = useState(curOffer.ofr_precio);
    const [photoUrl1, setPhotoUrl1] = useState(curOffer.ofr_tipo !== 1 ? curOffer.ofr_image1 : null);
    const [photoUrl2, setPhotoUrl2] = useState(curOffer.ofr_tipo !== 1 ? curOffer.ofr_image2 : null);
    const [photoUrl3, setPhotoUrl3] = useState(curOffer.ofr_tipo !== 1 ? curOffer.ofr_image3 : null);
    const [photoUrl4, setPhotoUrl4] = useState(curOffer.ofr_tipo !== 1 ? curOffer.ofr_image4 : null);
    const [photoUrl5, setPhotoUrl5] = useState(curOffer.ofr_tipo !== 1 ? curOffer.ofr_image5 : null);
    const [photoUrl6, setPhotoUrl6] = useState(curOffer.ofr_tipo !== 1 ? curOffer.ofr_image6 : null);
    const [photoUrl7, setPhotoUrl7] = useState(curOffer.ofr_tipo !== 1 ? curOffer.ofr_image7 : null);
    const [photoUrl8, setPhotoUrl8] = useState(curOffer.ofr_tipo !== 1 ? curOffer.ofr_image8 : null);
    const [photoUrl9, setPhotoUrl9] = useState(curOffer.ofr_tipo !== 1 ? curOffer.ofr_image9 : null);
    const [photoUrl10, setPhotoUrl10] = useState(curOffer.ofr_tipo !== 1 ? curOffer.ofr_image10 : null);

    //Estados para subida de foto
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState([]);
    const [fileListB64, setFileListB64] = useState([]);

    const { Option } = Select;
    const { TextArea } = Input;


    const selectServicio = (value) => {
        setServicioOferta(value);
    };

    const selectMedio = (value) => {
        setMediosOferta(value);
    };

    const selectBalcon = (value) => {
        setBalconOferta(value);
    };

    const myFormat = (valor, tipo) => {
        if (Number.isNaN(Number.parseFloat(valor))) {
            message.error('Valor incorrecto');
            return false;
        }
        if (tipo === 1) {
            setPrecioOferta(valor);
        } else if (tipo === 2) {
            setAreaOferta(valor);
        } else if (tipo === 3) {
            setLoteOferta(valor);
        } else if (tipo === 4) {
            setAlcobasOferta(valor);
        } else if (tipo === 5) {
            setBanosOferta(valor);
        } else if (tipo === 6) {
            setParkingOferta(valor);
        } else {
            setElevadorOferta(valor);
        }
    }

    // Accion para Obtener las nuevas imagenes 
    const handleBefore = async (file) => {
        ///////////////////////////////////////////
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("Solo se permiten imagenes JPG/PNG!");
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Solo esta permitido archivos de Menos 3MB!");
            return false;
        }
        ///////////////////////////////////////////

        let imgBase64 = await getBase64(file); // Obtengo la conversion de la Imagen a Base 64
        console.log(imgBase64.substr(0, 50));

        // Convierto la imagen en Base64 para el envio a Webservices
        //let imgb64end = imgBase64.split(";base64,"); // Para solo tomar el objeto [1] para la imagen base64 limpia

        // Seteo de Valores para el Modal
        setPreviewTitle(file.name);
        setPreviewImage(imgBase64);

        // le Creo un uid al Objeto de la Nueva Imagen
        let uid = ((Math.random() * 16) | 0).toString(16);

        // Creo nuevo Objeto con la Imagen Nueva
        let newImg = { uid: uid, name: file.name, status: "done", url: imgBase64 };
        setFileListB64([...fileListB64, { base64: imgBase64 }]); // Guarda la Imagen para base64 Final
        setFileList([...fileList, newImg]); // Guarda la nueva imagen
    };


    // Cambio de Imagen
    const handleChange = (file) => {
        //console.log(fileList);
        //console.log(fileListB64);
    };

    // Imagen Preview
    const handlePreview = async (file) => {
        setPreviewImage(file.url);
        setPreviewVisible(true);
        setPreviewTitle(file.name);
    };

    // Imagen Preview
    const handleViewImage = async (file) => {
        setPreviewImage(file);
        setPreviewVisible(true);
        setPreviewTitle(null);
    };

    const handleCancel = () => setPreviewVisible(false);

    // Elimino Imagen
    const onRemove = (file) => {
        // Borrar el Objeto Principal
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);

        // Borrar el Objeto Base64
        const newFileListB64 = fileListB64.slice();
        newFileListB64.splice(index, 1);
        setFileListB64(newFileListB64);
    };

    // Mostar Boton Upload
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Seleccionar</div>
        </div>
    );

    // ENVIO A WEBSERVICES
    const uploadOferta = async () => {
        if (
            Number.parseFloat(precioOferta) === 0 ||
            Number.parseFloat(areaOferta) === 0 ||
            notaOferta.length === 0
        ) {
            showNotification(
                "topRight",
                2,
                "ACTUALIZAR OFERTA",
                "Precio, Area y Descripcion son datos obligatorios... por favor verificar!"
            );
            return false;
        } else {
            if (curOffer.ofr_tipo !== 1 && photoUrl1.length === 0) {
                showNotification(
                    "topRight",
                    2,
                    "ACTUALIZAR OFERTA",
                    "La oferta requiere la foto principal... por favor verificar!"
                );
                return false;
            } else {
                setLoadingButton(true);
                let record = {
                    ofr_tipo: curOffer.ofr_tipo,
                    ofr_id: curOffer.id,
                    brk_id: curOffer.brk_id,
                    brk_parent: curOffer.brk_parent,
                    cur_area: curOffer.ofr_area,
                    ofr_area: areaOferta,
                    ofr_lote: loteOferta,
                    cur_precio: curOffer.ofr_precio,
                    ofr_precio: precioOferta,
                    ofr_alcobas: alcobasOferta,
                    ofr_servicio: servicioOferta,
                    ofr_banos: banosOferta,
                    ofr_medios: mediosOferta,
                    ofr_balcon: balconOferta,
                    ofr_address: unidadOferta,
                    ofr_parking: parkingOferta,
                    ofr_elevadores: elevadorOferta,
                    ofr_nota: notaOferta,
                    upd_fotos: fileList.length > 0 ? 1 : 0,
                    ofr_image1: fileList.length > 0 ? fileList[0].url : 'NA',
                    ofr_image2: fileList.length > 1 ? fileList[1].url : 'NA',
                    ofr_image3: fileList.length > 2 ? fileList[2].url : 'NA',
                    ofr_image4: fileList.length > 3 ? fileList[3].url : 'NA',
                    ofr_image5: fileList.length > 4 ? fileList[4].url : 'NA',
                    ofr_image6: fileList.length > 5 ? fileList[5].url : 'NA',
                    ofr_image7: fileList.length > 6 ? fileList[6].url : 'NA',
                    ofr_image8: fileList.length > 7 ? fileList[7].url : 'NA',
                    ofr_image9: fileList.length > 8 ? fileList[8].url : 'NA',
                    ofr_image10: fileList.length > 9 ? fileList[9].url : 'NA',
                    ofr_url1: photoUrl1,
                    ofr_url2: photoUrl2,
                    ofr_url3: photoUrl3,
                    ofr_url4: photoUrl4,
                    ofr_url5: photoUrl5,
                    ofr_url6: photoUrl6,
                    ofr_url7: photoUrl7,
                    ofr_url8: photoUrl8,
                    ofr_url9: photoUrl9,
                    ofr_url10: photoUrl10,
                };
                let res_oferta = await SRV.updateOferta(record);
                if (res_oferta.type && Number.parseInt(res_oferta.type) > 0) {
                    //dispatch(reLoadEnviroment(res_oferta.datos));
                    if (Number.parseInt(res_oferta.matches) > 0) {
                        showNotification(
                            "topRight",
                            4,
                            "REGISTRAR OFERTA",
                            "Se registró la OFERTA y se encontraron COINCIDENCIAS"
                        );
                    } else {
                        showNotification(
                            "topRight",
                            4,
                            "ACTUALIZAR OFERTA",
                            "Proceso de actualizacion EXITOSO"
                        );
                    }
                } else {
                    showNotification(
                        "topRight",
                        2,
                        "REGISTRAR OFERTA",
                        res_oferta.message
                    );
                }
            }
        }
        setLoadingButton(false);
        setCurOption(1);
    };



    return (
        <>
            <div>
                <ProfileHeader curBroker={broker} curOption={curOption} setCurOption={setCurOption} />
                <div className="p-5 bg-gray-100">
                    <div className="md:grid md:grid-cols-1 max-w-7xl mx-auto md:gap-6">
                        <div className="mt-5 bg-gray-100 md:mt-0 md:col-span-1">
                            <form action="#" method="POST">
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-8">
                                                <h1 className='mx-auto flex justify-center text-3xl text-purple-800'>Actualizar Oferta</h1>
                                            </div>
                                            <div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-6">
                                                <label htmlFor="ubicacion-inmueble" className="block text-lg font-medium text-purple-700">
                                                    Ubicación del Inmueble
                                                </label>
                                                <div className="mt-1 col-span-3 flex rounded-md shadow-sm">
                                                    <h1 className="block text-2xl font-light text-gray-600">{curOffer.place_describe}</h1>
                                                    <LocationMarkerIcon className="h-7 w-7 ml-4 text-purple-500" aria-hidden="true" />
                                                </div>
                                            </div>

                                            <div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-lg font-medium text-purple-600">
                                                    {'Precio $' + myNumber(1, precioOferta, 2)}
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={precioOferta}
                                                        onChange={(value) => myFormat(value, 1)}
                                                        name='precioOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-8">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {'Punto de Referencia (Opcional)'}
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Input placeholder="Punto de Referencia" name='unidadOferta' value={unidadOferta} onChange={(e) => setUnidadOferta(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="tipo-inmueble" className="block text-base font-medium text-purple-600">
                                                    Tipo de Inmueble
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <h1 className="block text-2xl font-light text-gray-600">{curOffer.tin_nombre}</h1>
                                                </div>
                                            </div>

                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="motivo" className="block text-base font-medium text-purple-600">
                                                    Motivo Oferta
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <h1 className="block text-2xl font-light text-gray-600">{curOffer.mtv_nombre}</h1>
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Area: {myNumber(1, areaOferta, 2)}m<sup>2</sup>
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={areaOferta}
                                                        onChange={(value) => myFormat(value, 2)}
                                                        name='areaOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%" }}
                                                        size='large'
                                                        formatter={(value) =>
                                                            `${value}`.replace(/\B(?=(\d{0})+(?!\d))/g, ",")
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Lote: {myNumber(1, loteOferta, 2)}m<sup>2</sup>
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={loteOferta}
                                                        onChange={(value) => myFormat(value, 3)}
                                                        name='loteOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {myNumber(1, alcobasOferta, 0)} Alcobas
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={alcobasOferta}
                                                        onChange={(value) => myFormat(value, 4)}
                                                        name='alcobasOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%", textAlign: "right" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Alcoba de Servicio
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Select
                                                        defaultValue={servicioOferta}
                                                        style={{ width: '100%' }}
                                                        onChange={selectServicio}
                                                        value={servicioOferta}
                                                        name='servicioOferta'
                                                        showSearch
                                                        size='large'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value={0}>NO</Option>
                                                        <Option value={1}>SI</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {myNumber(1, banosOferta, 0)} Baños
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={banosOferta}
                                                        onChange={(value) => myFormat(value, 5)}
                                                        name='banosOferta'
                                                        controls={false}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%", textAlign: "right" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Medios Baños
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Select
                                                        defaultValue={mediosOferta}
                                                        style={{ width: '100%' }}
                                                        onChange={selectMedio}
                                                        value={mediosOferta}
                                                        name='mediosOferta'
                                                        showSearch
                                                        size='large'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value={0}>NO</Option>
                                                        <Option value={1}>SI</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-2 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {myNumber(1, parkingOferta, 0)} Parqueaderos
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={parkingOferta}
                                                        onChange={(value) => myFormat(value, 6)}
                                                        name='parkingOferta'
                                                        controls={false}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%", textAlign: "right" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-2 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Tiene Balcon
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Select
                                                        defaultValue={balconOferta}
                                                        style={{ width: '100%' }}
                                                        onChange={selectBalcon}
                                                        value={balconOferta}
                                                        name='balconOferta'
                                                        showSearch
                                                        size='large'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value={0}>NO</Option>
                                                        <Option value={1}>SI</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-2 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {myNumber(1, elevadorOferta, 0)} Ascensonres
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={elevadorOferta}
                                                        onChange={(value) => myFormat(value, 7)}
                                                        name='elevadorOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%", textAlign: "right" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div>
                                            <label htmlFor="about" className="block text-base font-medium text-purple-600">
                                                Describe la Propiedad
                                            </label>
                                            <div className="mt-1">
                                                <TextArea rows={4} value={notaOferta} onChange={(e) => setNotaOferta(e.target.value)} name='notaOferta' placeholder="Breve descripcion de la propiedad... Máximo 250 caracteres" maxLength={250} />
                                            </div>
                                        </div>
                                        <div className="col-span-8 sm:col-span-8">
                                            <div className="flex space-x-3 overflow-scroll scrollbar-hide p-3 -ml-3">
                                                {imagesOffer.map((file) => (
                                                    <SmallCard key={file.id} img={file.source} handleViewImage={handleViewImage} />
                                                ))}
                                            </div>

                                            {curOffer.ofr_tipo === 1 ? (
                                                <div>
                                                    <label className="block text-lg font-medium text-purple-600">Actualizar Imagenes (Seleccione el nuevo conjunto de imagenes para la oferta)</label>
                                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                        <div className="space-y-1 text-center">
                                                            <Upload
                                                                listType="picture-card"
                                                                fileList={fileList}
                                                                beforeUpload={handleBefore}
                                                                onChange={handleChange}
                                                                onPreview={handlePreview}
                                                                onRemove={onRemove}
                                                                multiple={true}
                                                            >
                                                                {fileList.length >= 10 ? null : uploadButton}
                                                            </Upload>

                                                            <p className="text-xs text-gray-500">PNG, JPG hasta 2MB</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="grid grid-cols-8 gap-6">
                                                    <div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-8">
                                                        <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                            {'Ingrese la URL de las imagenes'}
                                                        </label>
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <Input placeholder="Imagen principal requerida!" value={photoUrl1} onChange={(e) => setPhotoUrl1(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                        </div>
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <Input placeholder="Imagen # 2" value={photoUrl2} onChange={(e) => setPhotoUrl2(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                        </div>
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <Input placeholder="Imagen # 3" value={photoUrl3} onChange={(e) => setPhotoUrl3(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                        </div>
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <Input placeholder="Imagen # 4" value={photoUrl4} onChange={(e) => setPhotoUrl4(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                        </div>
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <Input placeholder="Imagen # 5" value={photoUrl5} onChange={(e) => setPhotoUrl5(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                        </div>
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <Input placeholder="Imagen # 6" value={photoUrl6} onChange={(e) => setPhotoUrl6(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                        </div>
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <Input placeholder="Imagen # 7" value={photoUrl7} onChange={(e) => setPhotoUrl7(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                        </div>
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <Input placeholder="Imagen # 8" value={photoUrl8} onChange={(e) => setPhotoUrl8(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                        </div>
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <Input placeholder="Imagen # 9" value={photoUrl9} onChange={(e) => setPhotoUrl9(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                        </div>
                                                        <div className="mt-1 flex rounded-md shadow-sm">
                                                            <Input placeholder="Imagen # 10" value={photoUrl10} onChange={(e) => setPhotoUrl10(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                        </div>
                                                    </div>
                                                </div>

                                            )}
                                        </div>
                                    </div>
                                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                        <Popconfirm
                                            placement="top"
                                            title="CONFIRMAR EL MOVIMIENTO?"
                                            onConfirm={() => uploadOferta()}
                                            okText="Si"
                                            cancelText="No"
                                            okType='default'
                                        >
                                            <Button
                                                type="primary"
                                                className='btnLila'
                                                shape="round"
                                                block
                                                loading={loadingButton}
                                                size='large'
                                            >
                                                REGISTRAR LA OFERTA
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                    <Modal
                                        visible={previewVisible}
                                        title={previewTitle}
                                        footer={null}
                                        onCancel={handleCancel}
                                        width={'60%'}
                                    >
                                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="hidden sm:block" aria-hidden="true">
                    <div className="py-5">
                        <div className="border-t border-gray-200" />
                    </div>
                </div>

            </div>

        </>
    )
}