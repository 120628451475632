import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from "validator";
import { Input } from 'antd';
import {
    AtSymbolIcon,
    LockClosedIcon,
    UserIcon
} from "@heroicons/react/outline";
import logo from '../../assets/img/logo-4.png'
import { Link } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import { startRegisterWithEmailPasswordName } from '../../actions/auth';
import { removeError, setError } from '../../actions/ui';

export const RegisterScreen = () => {

    const dispatch = useDispatch();
    const { msgError } = useSelector(state => state.ui);

    const [formValues, handleInputChange] = useForm({
        name: '',
        email: '',
        password: '',
        password2: ''
    })

    const { name, email, password, password2 } = formValues;

    const handleRegister = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            dispatch(startRegisterWithEmailPasswordName(email, password, name));
        }
    }

    const isFormValid = () => {
        if (name.trim().length === 0) {
            dispatch(setError('Nombre es requerido'))
            return false;
        } else if (!validator.isEmail(email)) {
            dispatch(setError('Email incorrecto'))
            return false;
        } else if (password !== password2 || password.length < 6) {
            dispatch(setError('El password debe tener al menos 6 caracteres y ser igual al confirmar'))
            return false;
        }
        dispatch(removeError());
        return true;
    }

    return (
        <div className="flex flex-col h-screen bg-[url('/src/assets/img/bg-3.jpg')]">
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="h-36 w-auto m-auto"
                        src={logo}
                        alt="Workflow"
                    />
                    <h2 className="mt-6 text-center text-xl font-light text-gray-500">Inicia sesión en Brokerhood</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow shadow-purple-200 sm:rounded-lg sm:px-10">
                        <form onSubmit={handleRegister} className="space-y-6" action="#" method="POST">
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    Nombre Completo
                                </label>
                                <div className="mt-1">
                                    <Input placeholder='Nombre Completo' name='name' onChange={handleInputChange} value={name} maxLength={80} prefix={<UserIcon className='h-6 text-gray-400' />} />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Direccion de correo
                                </label>
                                <div className="mt-1">
                                    <Input placeholder='myuser@email.com' name='email' onChange={handleInputChange} value={email} maxLength={60} prefix={<AtSymbolIcon className='h-6 text-gray-400' />} />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="mt-1">
                                    <Input.Password placeholder='my-secure-password' name='password' onChange={handleInputChange} value={password} prefix={<LockClosedIcon className='h-6 text-gray-400' />} />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password2" className="block text-sm font-medium text-gray-700">
                                    Repetir Password
                                </label>
                                <div className="mt-1">
                                    <Input.Password placeholder='my-secure-password' name='password2' onChange={handleInputChange} value={password2} prefix={<LockClosedIcon className='h-6 text-gray-400' />} />
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-900 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                >
                                    Registrarse en Brokerhood
                                </button>
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <Link to='/auth/login' className="font-medium text-gray-600 hover:text-gray-500">
                                        Iniciar Sesión
                                    </Link>
                                </div>

                                <div className="text-sm">
                                    <p className="font-medium text-purple-800 hover:text-purple-500">
                                        Olvidé mi contraseña?
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}
