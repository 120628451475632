import { useState } from 'react';
import { useSelector } from 'react-redux';
import { LocationMarkerIcon } from '@heroicons/react/outline'
import { Switch } from '@headlessui/react'
import { PlusOutlined } from "@ant-design/icons";
import {
    Upload,
    Input,
    InputNumber,
    Popconfirm,
    message,
    Button,
    Select,
    Modal,
} from "antd";
import ProfileHeader from './ProfileHeader'
import { getBase64, myNumber } from '../utilities/ArrayFunctions';
import SRV from '../helpers/Service';
import { showNotification } from '../utilities/ShowNotifications';
import AutoComplete from './AutoComplete';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function CreateOffer({ broker, curOption, setCurOption }) {
    const [enabled, setEnabled] = useState(true);
    const { zonas, tipos_inm } = useSelector(state => state.appEnv.appEnv);
    const [loadingButton, setLoadingButton] = useState(false);
    const [locActiva, setLocActiva] = useState(1)

    const [tipoInmuebleId, setTipoInmuebleId] = useState(0);
    const [zonaId, setZonaId] = useState(0);
    const [motivoId, setMotivoId] = useState(0);
    const [areaOferta, setAreaOferta] = useState(0);
    const [servicioOferta, setServicioOferta] = useState(0);
    const [balconOferta, setBalconOferta] = useState(0);
    const [unidadOferta, setUnidadOferta] = useState(null);
    const [loteOferta, setLoteOferta] = useState(0);
    const [alcobasOferta, setAlcobasOferta] = useState(0);
    const [banosOferta, setBanosOferta] = useState(0);
    const [mediosOferta, setMediosOferta] = useState(0);
    const [elevadorOferta, setElevadorOferta] = useState(0);
    const [parkingOferta, setParkingOferta] = useState(0);
    const [notaOferta, setNotaOferta] = useState('');
    const [precioOferta, setPrecioOferta] = useState(0);
    const [selectLocat, setSelectLocat] = useState({
        place: '',
        description: '',
        latitud: 0,
        longitud: 0
    })
    const [photoUrl1, setPhotoUrl1] = useState(null);
    const [photoUrl2, setPhotoUrl2] = useState(null);
    const [photoUrl3, setPhotoUrl3] = useState(null);
    const [photoUrl4, setPhotoUrl4] = useState(null);
    const [photoUrl5, setPhotoUrl5] = useState(null);
    const [photoUrl6, setPhotoUrl6] = useState(null);
    const [photoUrl7, setPhotoUrl7] = useState(null);
    const [photoUrl8, setPhotoUrl8] = useState(null);
    const [photoUrl9, setPhotoUrl9] = useState(null);
    const [photoUrl10, setPhotoUrl10] = useState(null);

    //Estados para subida de foto
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState([]);
    const [fileListB64, setFileListB64] = useState([]);

    const { Option } = Select;
    const { TextArea } = Input;

    const selectTipoInmueble = (value) => {
        setTipoInmuebleId(value);
    };
    const selectZonaId = (value) => {
        setZonaId(value);
    };

    const selectMotivo = (value) => {
        setMotivoId(value);
    };

    const selectServicio = (value) => {
        setServicioOferta(value);
    };

    const selectMedio = (value) => {
        setMediosOferta(value);
    };

    const selectBalcon = (value) => {
        setBalconOferta(value);
    };

    const switchLocation = (tipo) => {
        setZonaId(0);
        setSelectLocat({
            place: '',
            description: '',
            latitud: 0,
            longitud: 0
        })
        setLocActiva(tipo);
    };

    const myFormat = (valor, tipo) => {
        if (Number.isNaN(Number.parseFloat(valor))) {
            message.error('Valor incorrecto');
            return false;
        }
        if (tipo === 1) {
            setPrecioOferta(valor);
        } else if (tipo === 2) {
            setAreaOferta(valor);
        } else if (tipo === 3) {
            setLoteOferta(valor);
        } else if (tipo === 4) {
            setAlcobasOferta(valor);
        } else if (tipo === 5) {
            setBanosOferta(valor);
        } else if (tipo === 6) {
            setParkingOferta(valor);
        } else {
            setElevadorOferta(valor);
        }
    }

    //Nuevas acciones gestion de imagenes
    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    //Fin nuevas acciones gestion de imagenes


    // Accion para Obtener las nuevas imagenes
    const handleBefore = async (file) => {
        ///////////////////////////////////////////
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("Solo se permiten imagenes JPG/PNG!");
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Solo esta permitido archivos de Menos 3MB!");
            return false;
        }
        ///////////////////////////////////////////

        let imgBase64 = await getBase64(file); // Obtengo la conversion de la Imagen a Base 64
        //console.log(imgBase64.substr(0, 50));

        // Convierto la imagen en Base64 para el envio a Webservices
        //let imgb64end = imgBase64.split(";base64,"); // Para solo tomar el objeto [1] para la imagen base64 limpia

        // Seteo de Valores para el Modal
        setPreviewTitle(file.name);
        setPreviewImage(imgBase64);

        // le Creo un uid al Objeto de la Nueva Imagen
        let uid = ((Math.random() * 16) | 0).toString(16);

        // Creo nuevo Objeto con la Imagen Nueva
        let newImg = { uid: uid, name: file.name, status: "done", url: imgBase64 };
        setFileListB64([...fileListB64, { base64: imgBase64 }]); // Guarda la Imagen para base64 Final
        setFileList([...fileList, newImg]); // Guarda la nueva imagen
    };

    // Cambio de Imagen
    const handleChange = (file) => {
        //console.log(fileList);
        //console.log(fileListB64);
    };
    //const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    // Imagen Preview
    /*const handlePreview = async (file) => {
        setPreviewImage(file.url);
        setPreviewVisible(true);
        setPreviewTitle(file.name);
    };*/

    // Elimino Imagen
    const onRemove = (file) => {
        // Borrar el Objeto Principal
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);

        // Borrar el Objeto Base64
        const newFileListB64 = fileListB64.slice();
        newFileListB64.splice(index, 1);
        setFileListB64(newFileListB64);
    };

    // Mostar Boton Upload
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Seleccionar</div>
        </div>
    );

    // ENVIO A WEBSERVICES
    const uploadOferta = async () => {
        if (
            (Number.parseInt(zonaId) === 0 && selectLocat.place.length === 0) ||
            Number.parseInt(tipoInmuebleId) === 0 ||
            Number.parseInt(motivoId) === 0 ||
            Number.parseFloat(areaOferta) === 0 ||
            Number.parseFloat(precioOferta) === 0 ||
            notaOferta.length === 0
        ) {
            showNotification(
                "topRight",
                2,
                "REGISTRAR OFERTA",
                "Ubicacion, Tipo de Inmueble, Motivo, Area, Precio y Descripcion son datos obligatorios... por favor verificar!"
            );
            return false;
        } else {
            if ((enabled && fileList.length === 0) || (!enabled && photoUrl1.length === 0)) {
                showNotification(
                    "topRight",
                    2,
                    "REGISTRAR OFERTA",
                    "La oferta debe tener por lo menos una imagen... verifique!"
                );
                return false;
            } else {
                setLoadingButton(true);
                let record = {
                    ofr_tipo: enabled ? 1 : 2,
                    brk_id: broker.id,
                    brk_parent: broker.brk_parent,
                    zna_id: zonaId,
                    tin_id: tipoInmuebleId,
                    mtv_id: motivoId,
                    ofr_area: areaOferta,
                    ofr_lote: loteOferta,
                    ofr_precio: precioOferta,
                    ofr_alcobas: alcobasOferta,
                    ofr_servicio: servicioOferta,
                    ofr_banos: banosOferta,
                    ofr_medios: mediosOferta,
                    ofr_balcon: balconOferta,
                    ofr_address: unidadOferta,
                    ofr_parking: parkingOferta,
                    ofr_elevadores: elevadorOferta,
                    ofr_nota: notaOferta,
                    ofr_image1: fileList.length > 0 ? fileList[0].url : 'NA',
                    ofr_image2: fileList.length > 1 ? fileList[1].url : 'NA',
                    ofr_image3: fileList.length > 2 ? fileList[2].url : 'NA',
                    ofr_image4: fileList.length > 3 ? fileList[3].url : 'NA',
                    ofr_image5: fileList.length > 4 ? fileList[4].url : 'NA',
                    ofr_image6: fileList.length > 5 ? fileList[5].url : 'NA',
                    ofr_image7: fileList.length > 6 ? fileList[6].url : 'NA',
                    ofr_image8: fileList.length > 7 ? fileList[7].url : 'NA',
                    ofr_image9: fileList.length > 8 ? fileList[8].url : 'NA',
                    ofr_image10: fileList.length > 9 ? fileList[9].url : 'NA',
                    placeId: selectLocat.place,
                    placeDescription: selectLocat.description,
                    ofr_latitud: selectLocat.latitud,
                    ofr_longitud: selectLocat.longitud,
                    ofr_url1: photoUrl1,
                    ofr_url2: photoUrl2,
                    ofr_url3: photoUrl3,
                    ofr_url4: photoUrl4,
                    ofr_url5: photoUrl5,
                    ofr_url6: photoUrl6,
                    ofr_url7: photoUrl7,
                    ofr_url8: photoUrl8,
                    ofr_url9: photoUrl9,
                    ofr_url10: photoUrl10,
                };
                let res_oferta = await SRV.sendOferta(record);
                if (res_oferta.type && Number.parseInt(res_oferta.type) > 0) {
                    //dispatch(reLoadEnviroment(res_oferta.datos));
                    if (Number.parseInt(res_oferta.matches) > 0) {
                        showNotification(
                            "topRight",
                            4,
                            "REGISTRAR OFERTA",
                            "Se registró la OFERTA y se encontraron COINCIDENCIAS"
                        );
                    } else {
                        showNotification(
                            "topRight",
                            4,
                            "REGISTRAR OFERTA",
                            "Proceso de registro EXITOSO"
                        );
                    }
                } else {
                    showNotification(
                        "topRight",
                        2,
                        "REGISTRAR OFERTA",
                        res_oferta.message
                    );
                }
            }
        }
        setLoadingButton(false);
        setCurOption(1);
    };



    return (
        <>
            <div>
                <ProfileHeader curBroker={broker} curOption={curOption} setCurOption={setCurOption} />
                <div className="p-5 bg-gray-100">
                    <div className="md:grid md:grid-cols-1 max-w-7xl mx-auto md:gap-6">
                        <div className="mt-5 bg-gray-100 md:mt-0 md:col-span-1">
                            <form action="#" method="POST">
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-8">
                                                <h1 className='mx-auto flex justify-center text-3xl text-purple-800'>Registrar Oferta</h1>
                                            </div>
                                            {locActiva === 1
                                                ?
                                                (<div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-6">
                                                    <label htmlFor="ubicacion-inmueble" className="block text-lg font-medium text-purple-700">
                                                        Ubicación del Inmueble
                                                    </label>
                                                    <div className="mt-1 col-span-3 flex rounded-md shadow-sm">
                                                        <Select
                                                            defaultValue={zonaId === 0 ? 'Seleccione Ubicacion' : zonaId}
                                                            style={{ width: '100%' }}
                                                            onChange={selectZonaId}
                                                            value={zonaId === 0 ? 'Seleccione Ubicacion' : zonaId}
                                                            name='zonaId'
                                                            showSearch
                                                            size='large'
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {zonas.map(zona => (
                                                                <Option key={zona.id}>{zona.descripcion}</Option>
                                                            ))}
                                                        </Select>
                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center p-2 ml-3 border border-blue-300 rounded-full shadow-sm text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                        >
                                                            <LocationMarkerIcon className="h-5 w-5 text-blue-500" aria-hidden="true" onClick={() => switchLocation(2)} />
                                                        </button>
                                                    </div>
                                                </div>
                                                ) : (
                                                    <div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-6">
                                                        <label htmlFor="ubicacion-inmueble" className="block text-lg font-medium text-purple-700">
                                                            Buscar en Google Place
                                                        </label>
                                                        <div className="mt-1 col-span-3 flex rounded-md shadow-sm">
                                                            <AutoComplete setSelectLocat={setSelectLocat} />
                                                            <button
                                                                type="button"
                                                                className="inline-flex items-center p-2 ml-3 border border-purple-300 rounded-full shadow-sm text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                                            >
                                                                <LocationMarkerIcon className="h-5 w-5 text-purple-600" aria-hidden="true" onClick={() => switchLocation(1)} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            <div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-lg font-medium text-purple-600">
                                                    {'Precio $' + myNumber(1, precioOferta, 2)}
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={precioOferta}
                                                        onChange={(value) => myFormat(value, 1)}
                                                        name='precioOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-8">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {'Punto de Referencia (Opcional)'}
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Input placeholder="Punto de Referencia" name='unidadOferta' value={unidadOferta} onChange={(e) => setUnidadOferta(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="tipo-inmueble" className="block text-base font-medium text-purple-600">
                                                    Tipo de Inmueble
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Select
                                                        defaultValue={tipoInmuebleId === 0 ? 'Seleccione tipo de Inmueble' : tipoInmuebleId}
                                                        style={{ width: '100%' }}
                                                        onChange={selectTipoInmueble}
                                                        value={tipoInmuebleId === 0 ? 'Seleccione el Tipo' : tipoInmuebleId}
                                                        showSearch
                                                        size='large'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {tipos_inm.map(tipo_inm => (
                                                            <Option key={tipo_inm.value}>{tipo_inm.label}</Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </div>

                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="motivo" className="block text-base font-medium text-purple-600">
                                                    Motivo Oferta
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Select
                                                        defaultValue={motivoId === '0' ? 'Motivo de la Oferta' : motivoId}
                                                        style={{ width: '100%' }}
                                                        onChange={selectMotivo}
                                                        value={motivoId}
                                                        name='motivoId'
                                                        showSearch
                                                        size='large'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value={0}>Seleccione Motivo</Option>
                                                        <Option value={1}>VENTA</Option>
                                                        <Option value={2}>ALQUILER</Option>
                                                        <Option value={3}>PERMUTA</Option>
                                                        <Option value={4}>CUALQUIERA</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Area: {myNumber(1, areaOferta, 2)}m<sup>2</sup>
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={areaOferta}
                                                        onChange={(value) => myFormat(value, 2)}
                                                        name='areaOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%" }}
                                                        size='large'
                                                        formatter={(value) =>
                                                            `${value}`.replace(/\B(?=(\d{0})+(?!\d))/g, ",")
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Lote: {myNumber(1, loteOferta, 2)}m<sup>2</sup>
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={loteOferta}
                                                        onChange={(value) => myFormat(value, 3)}
                                                        name='loteOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {myNumber(1, alcobasOferta, 0)} Alcobas
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={alcobasOferta}
                                                        onChange={(value) => myFormat(value, 4)}
                                                        name='alcobasOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%", textAlign: "right" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Alcoba de Servicio
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Select
                                                        defaultValue={servicioOferta}
                                                        style={{ width: '100%' }}
                                                        onChange={selectServicio}
                                                        value={servicioOferta}
                                                        name='servicioOferta'
                                                        showSearch
                                                        size='large'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value={0}>NO</Option>
                                                        <Option value={1}>SI</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {myNumber(1, banosOferta, 0)} Baños
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={banosOferta}
                                                        onChange={(value) => myFormat(value, 5)}
                                                        name='banosOferta'
                                                        controls={false}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%", textAlign: "right" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Medios Baños
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Select
                                                        defaultValue={mediosOferta}
                                                        style={{ width: '100%' }}
                                                        onChange={selectMedio}
                                                        value={mediosOferta}
                                                        name='mediosOferta'
                                                        showSearch
                                                        size='large'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value={0}>NO</Option>
                                                        <Option value={1}>SI</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-2 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {myNumber(1, parkingOferta, 0)} Parqueaderos
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={parkingOferta}
                                                        onChange={(value) => myFormat(value, 6)}
                                                        name='parkingOferta'
                                                        controls={false}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%", textAlign: "right" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-2 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Tiene Balcon
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <Select
                                                        defaultValue={balconOferta}
                                                        style={{ width: '100%' }}
                                                        onChange={selectBalcon}
                                                        value={balconOferta}
                                                        name='balconOferta'
                                                        showSearch
                                                        size='large'
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        <Option value={0}>NO</Option>
                                                        <Option value={1}>SI</Option>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-2 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {myNumber(1, elevadorOferta, 0)} Ascensonres
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <InputNumber
                                                        value={elevadorOferta}
                                                        onChange={(value) => myFormat(value, 7)}
                                                        name='elevadorOferta'
                                                        controls={false}
                                                        min={0}
                                                        style={{ height: 40, borderColor: '#d1d5db', width: "100%", textAlign: "right" }}
                                                        size='large'
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div>
                                            <label htmlFor="about" className="block text-base font-medium text-purple-600">
                                                Describe la Propiedad
                                            </label>
                                            <div className="mt-1">
                                                <TextArea rows={4} value={notaOferta} onChange={(e) => setNotaOferta(e.target.value)} name='notaOferta' placeholder="Breve descripcion de la propiedad... Máximo 250 caracteres" maxLength={250} />
                                            </div>
                                        </div>
                                        <div className='flex justify-center '>
                                            <Switch.Group as="div" className="flex items-center">
                                                <Switch
                                                    checked={enabled}
                                                    onChange={setEnabled}
                                                    className={classNames(
                                                        enabled ? 'bg-purple-800' : 'bg-gray-200',
                                                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500'
                                                    )}
                                                >
                                                    <span
                                                        aria-hidden="true"
                                                        className={classNames(
                                                            enabled ? 'translate-x-5' : 'translate-x-0',
                                                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                        )}
                                                    />
                                                </Switch>
                                                <Switch.Label as="span" className="ml-3">
                                                    <span className="text-sm font-medium text-gray-900">{enabled ? 'Agegar URLs' : 'Subir fotos manuales'} </span>
                                                    <span className="text-sm text-gray-500">(Opciones de carga de imagenes)</span>
                                                </Switch.Label>
                                            </Switch.Group>
                                        </div>

                                        {enabled ? (
                                            <div>
                                                <label className="block text-lg font-medium text-purple-600">Seleccionar Imagenes</label>
                                                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                    <div className="space-y-1 text-center">
                                                        <Upload
                                                            listType="picture-card"
                                                            fileList={fileList}
                                                            beforeUpload={handleBefore}
                                                            onChange={handleChange}
                                                            onPreview={handlePreview}
                                                            onRemove={onRemove}
                                                        >
                                                            {fileList.length >= 10 ? null : uploadButton}
                                                        </Upload>

                                                        <p className="text-xs text-gray-500">PNG, JPG hasta 2MB</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="grid grid-cols-8 gap-6">
                                                <div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-8">
                                                    <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                        {'Ingrese la URL de las imagenes'}
                                                    </label>
                                                    <div className="mt-1 flex rounded-md shadow-sm">
                                                        <Input placeholder="Imagen principal requerida!" value={photoUrl1} onChange={(e) => setPhotoUrl1(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                    </div>
                                                    <div className="mt-1 flex rounded-md shadow-sm">
                                                        <Input placeholder="Imagen # 2" value={photoUrl2} onChange={(e) => setPhotoUrl2(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                    </div>
                                                    <div className="mt-1 flex rounded-md shadow-sm">
                                                        <Input placeholder="Imagen # 3" value={photoUrl3} onChange={(e) => setPhotoUrl3(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                    </div>
                                                    <div className="mt-1 flex rounded-md shadow-sm">
                                                        <Input placeholder="Imagen # 4" value={photoUrl4} onChange={(e) => setPhotoUrl4(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                    </div>
                                                    <div className="mt-1 flex rounded-md shadow-sm">
                                                        <Input placeholder="Imagen # 5" value={photoUrl5} onChange={(e) => setPhotoUrl5(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                    </div>
                                                    <div className="mt-1 flex rounded-md shadow-sm">
                                                        <Input placeholder="Imagen # 6" value={photoUrl6} onChange={(e) => setPhotoUrl6(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                    </div>
                                                    <div className="mt-1 flex rounded-md shadow-sm">
                                                        <Input placeholder="Imagen # 7" value={photoUrl7} onChange={(e) => setPhotoUrl7(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                    </div>
                                                    <div className="mt-1 flex rounded-md shadow-sm">
                                                        <Input placeholder="Imagen # 8" value={photoUrl8} onChange={(e) => setPhotoUrl8(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                    </div>
                                                    <div className="mt-1 flex rounded-md shadow-sm">
                                                        <Input placeholder="Imagen # 9" value={photoUrl9} onChange={(e) => setPhotoUrl9(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                    </div>
                                                    <div className="mt-1 flex rounded-md shadow-sm">
                                                        <Input placeholder="Imagen # 10" value={photoUrl10} onChange={(e) => setPhotoUrl10(e.target.value)} style={{ height: 40, borderColor: '#d1d5db' }} size='large' />
                                                    </div>
                                                </div>
                                            </div>

                                        )}
                                    </div>
                                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                        <Popconfirm
                                            placement="top"
                                            title="CONFIRMAR EL MOVIMIENTO?"
                                            onConfirm={() => uploadOferta()}
                                            okText="Si"
                                            cancelText="No"
                                            okType='default'
                                        >
                                            <Button
                                                type="primary"
                                                className='btnLila'
                                                shape="round"
                                                block
                                                loading={loadingButton}
                                                size='large'
                                            >
                                                REGISTRAR LA OFERTA
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                                <Modal
                                    visible={previewVisible}
                                    title={previewTitle}
                                    footer={null}
                                    onCancel={handleCancel}
                                    width={'60%'}
                                >
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="hidden sm:block" aria-hidden="true">
                    <div className="py-5">
                        <div className="border-t border-gray-200" />
                    </div>
                </div>

            </div>

        </>
    )
}