import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { useSelector } from 'react-redux';

const AutoComplete = ({ setSelectLocat }) => {
    const { settings } = useSelector(state => state.appEnv.appEnv);
    const arrApi = settings.sup_version.split('_');
    const curApi = '"' + arrApi[1] + '_' + arrApi[2] + '"';
    const [value, setValue] = useState(null);

    function getLocation(val) {
        setValue(val);
        console.log('Aca');
        console.log(val.value.place_id);
        console.log(val.value.description);

        /*geocodeByPlaceId(val.value.place_id)
            .then(results => console.log({ "coord": results[0].geometry.viewport }))
            .catch(error => console.error(error));*/

        geocodeByPlaceId(val.value.place_id)
            .then(results => getLatLng(results[0]))
            .then(({ lat, lng }) =>
                setSelectLocat({
                    place: val.value.place_id,
                    description: val.value.description,
                    latitud: lat,
                    longitud: lng
                })
                //console.log('Successfully got latitude and longitude', { lat, lng })
            );
    }

    return (
        <div className='flex w-full'>
            <GooglePlacesAutocomplete
                apiKey="AIzaSyCadN8K3_yCdcm9vjrngpEBHMvdydLPt0E"
                selectProps={{
                    value,
                    onChange: getLocation,
                }}

                onLoadFailed={(error) => (
                    console.error("Could not inject Google script", error)
                )}
            />
        </div>
    )
}

export default AutoComplete