import { useEffect, useState } from 'react';
import { LocationMarkerIcon } from '@heroicons/react/outline'
import {
    Input,
    Popconfirm,
    Button,
    Select,
    Modal,
} from "antd";
import ProfileHeader from './ProfileHeader'
import { arrayImage, myNumber } from '../utilities/ArrayFunctions';
import SRV from '../helpers/Service';
import { showNotification } from '../utilities/ShowNotifications';
import { URL_OFFERT } from '../utilities/Constants';
import SmallCard from './SmallCard';

export default function ShowDetalleOrder({ broker, curOffer, curOption, setCurOption }) {
    const [loadingButton, setLoadingButton] = useState(false);
    const [imagesOffer, setImagesOffer] = useState([])

    useEffect(() => {
        const url_photo = Number.parseInt(curOffer.ofr_tipo) === 1 ? URL_OFFERT : "";
        (async () => {
            const arrayUrls = arrayImage(curOffer, url_photo);
            setImagesOffer(arrayUrls);
            console.log(arrayUrls);
        })();
    }, [curOffer]);

    const [notaOferta, setNotaOferta] = useState('');
    const [resultado, setResultado] = useState(0)

    //Estados para subida de foto
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");

    const { Option } = Select;
    const { TextArea } = Input;

    const selectResultado = (value) => {
        setResultado(value);
    };

    // Imagen Preview
    const handleViewImage = async (file) => {
        setPreviewImage(file);
        setPreviewVisible(true);
        setPreviewTitle(null);
    };

    const handleCancel = () => setPreviewVisible(false);

    // ENVIO A WEBSERVICES
    const uploadOferta = async () => {
        if (
            Number.parseFloat(resultado) === 0 ||
            notaOferta.length === 0
        ) {
            showNotification(
                "topRight",
                2,
                "GESTIONAR COINCIDENCIA",
                "Resultado y Observaciones son datos obligatorios... por favor verificar!"
            );
        } else {
            setLoadingButton(true);
            let record = {
                brk_id: broker.id,
                cnc_id: curOffer.id,
                cnc_estado: resultado === 1 ? 3 : 2,
                cnc_tipo_cierre: resultado,
                cnc_nota_cierre: notaOferta
            };
            let res_oferta = await SRV.updateMatch(record);
            if (res_oferta.type && Number.parseInt(res_oferta.type) > 0) {
                showNotification(
                    "topRight",
                    4,
                    "ACTUALIZAR MATCH",
                    "Proceso de actualizacion EXITOSO"
                );
            } else {
                showNotification(
                    "topRight",
                    2,
                    "ACTUALIZAR MATCH",
                    res_oferta.message
                );
            }
        }
        setLoadingButton(false);
        setCurOption(1);
    };



    return (
        <>
            <div>
                <ProfileHeader curBroker={broker} curOption={curOption} setCurOption={setCurOption} />
                <div className="p-5 bg-gray-100">
                    <div className="md:grid md:grid-cols-1 max-w-7xl mx-auto md:gap-6">
                        <div className="mt-5 bg-gray-100 md:mt-0 md:col-span-1">
                            <form action="#" method="POST">
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-8">
                                                <h1 className='mx-auto flex justify-center text-3xl text-purple-800'>{'Oferta para la orden Nº: ' + curOffer.ord_id}</h1>
                                            </div>
                                            <div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-6">
                                                <label htmlFor="ubicacion-inmueble" className="block text-lg font-medium text-purple-700">
                                                    Ubicación del Inmueble
                                                </label>
                                                <div className="mt-1 col-span-3 flex rounded-md shadow-sm">
                                                    <h1 className="block text-xl font-light text-gray-600">{curOffer.place_describe}</h1>
                                                    <LocationMarkerIcon className="h-7 w-7 ml-4 text-purple-500" aria-hidden="true" />
                                                </div>
                                            </div>

                                            <div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-lg font-medium text-purple-600">
                                                    {'Precio $'}
                                                </label>
                                                <div className="mt-1 flex rounded-md justify-end shadow-sm">
                                                    <h1 className="block text-xl  font-light text-gray-600">{'$' + myNumber(1, curOffer.ofr_precio, 2)}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-8 md:col-span-8 lg:col-span-8">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    {'Punto de Referencia (Opcional)'}
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <h1 className="block text-xl font-light text-gray-600">{curOffer.ofr_address}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="tipo-inmueble" className="block text-base font-medium text-purple-600">
                                                    Tipo de Inmueble
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <h1 className="block text-xl font-light text-gray-600">{curOffer.tin_nombre}</h1>
                                                </div>
                                            </div>

                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="motivo" className="block text-base font-medium text-purple-600">
                                                    Motivo Oferta
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <h1 className="block text-xl font-light text-gray-600">{curOffer.mtv_nombre === 'COMPRAR' ? 'VENDER' : curOffer.mtv_nombre}</h1>
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Area en m<sup>2</sup>
                                                </label>
                                                <div className="mt-1 flex rounded-md justify-end shadow-sm">
                                                    <h1 className="block text-xl  font-light text-gray-600">{myNumber(1, curOffer.ofr_area, 2)}</h1>
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Lote en m<sup>2</sup>
                                                </label>
                                                <div className="mt-1 flex rounded-md justify-end shadow-sm">
                                                    <h1 className="block text-xl  font-light text-gray-600">{myNumber(1, curOffer.ofr_lote, 2)}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-8 gap-6">
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Alcobas
                                                </label>
                                                <div className="mt-1 flex rounded-md justify-end shadow-sm">
                                                    <h1 className="block text-xl  font-light text-gray-600">{myNumber(1, curOffer.ofr_alcobas, 2)}</h1>
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Alcoba de Servicio
                                                </label>
                                                <div className="mt-1 flex rounded-md justify-end shadow-sm">
                                                    <h1 className="block text-xl  font-light text-gray-600">{curOffer.ofr_servicio === 1 ? 'SI' : 'NO'}</h1>
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Número de Baños
                                                </label>
                                                <div className="mt-1 flex rounded-md justify-end shadow-sm">
                                                    <h1 className="block text-xl  font-light text-gray-600">{myNumber(1, curOffer.ofr_banos, 2)}</h1>
                                                </div>
                                            </div>
                                            <div className="col-span-8 sm:col-span-4 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Medios Baños
                                                </label>
                                                <div className="mt-1 flex rounded-md justify-end shadow-sm">
                                                    <h1 className="block text-xl  font-light text-gray-600">{curOffer.ofr_medios === 1 ? 'SI' : 'NO'}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-2 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Parqueaderos
                                                </label>
                                                <div className="mt-1 flex rounded-md justify-end shadow-sm">
                                                    <h1 className="block text-xl  font-light text-gray-600">{myNumber(1, curOffer.ofr_parking, 2)}</h1>
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-2 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Tiene Balcon
                                                </label>
                                                <div className="mt-1 flex rounded-md justify-end shadow-sm">
                                                    <h1 className="block text-xl  font-light text-gray-600">{curOffer.ofr_balcon === 1 ? 'SI' : 'NO'}</h1>
                                                </div>
                                            </div>
                                            <div className="col-span-6 sm:col-span-2 md:col-span-2 lg:col-span-2">
                                                <label htmlFor="unidad" className="block text-base font-medium text-purple-600">
                                                    Ascensonres
                                                </label>
                                                <div className="mt-1 flex rounded-md justify-end shadow-sm">
                                                    <h1 className="block text-xl  font-light text-gray-600">{myNumber(1, curOffer.ofr_elevadores, 2)}</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <dt className="block text-base font-medium text-purple-600">Detalles de la propiedad</dt>
                                            <dd className="mt-1 text-xl text-gray-900">
                                                {curOffer.ofr_nota}
                                            </dd>
                                        </div>
                                        <div className="col-span-8 sm:col-span-8">
                                            <div className="flex space-x-3 overflow-scroll scrollbar-hide p-3 -ml-3">
                                                {imagesOffer.map((file) => (
                                                    <SmallCard key={file.id} img={file.source} handleViewImage={handleViewImage} />
                                                ))}
                                            </div>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6 col-span-8 sm:col-span-4 md:col-span-4 lg:col-span-2">
                                            <label htmlFor="motivo" className="block text-base font-medium text-purple-600">
                                                Gestionar la Coincidencia
                                            </label>
                                            <div className="mt-1 flex rounded-md shadow-sm">
                                                <Select
                                                    defaultValue={resultado === '0' ? 'Motivo de la Oferta' : resultado}
                                                    style={{ width: '100%' }}
                                                    onChange={selectResultado}
                                                    value={resultado}
                                                    name='resultado'
                                                    showSearch
                                                    size='large'
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Option value={0}>Resultado al Cerrar</Option>
                                                    <Option value={1}>SE CIERRA LA COMPRA</Option>
                                                    <Option value={2}>SE DESCARTA LA OFERTA</Option>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="px-4 py-1 sm:px-6">
                                            <label htmlFor="about" className="block text-base font-medium text-purple-600">
                                                Observaciones al Cerrar
                                            </label>
                                            <div className="mt-1">
                                                <TextArea rows={4} value={notaOferta} onChange={(e) => setNotaOferta(e.target.value)} name='notaOferta' placeholder="Observaciones para dar por cerrada la OFERTA..." maxLength={250} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                        <Popconfirm
                                            placement="top"
                                            title="CONFIRMAR EL MOVIMIENTO?"
                                            onConfirm={() => uploadOferta()}
                                            okText="Si"
                                            cancelText="No"
                                            okType='default'
                                        >
                                            <Button
                                                type="primary"
                                                className='btnLila'
                                                shape="round"
                                                block
                                                loading={loadingButton}
                                                size='large'
                                            >
                                                CERRAR EL MATCH
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                    <Modal
                                        visible={previewVisible}
                                        title={previewTitle}
                                        footer={null}
                                        onCancel={handleCancel}
                                        width={'60%'}
                                    >
                                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="hidden sm:block" aria-hidden="true">
                    <div className="py-5">
                        <div className="border-t border-gray-200" />
                    </div>
                </div>

            </div>

        </>
    )
}