import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, Empty, message } from 'antd'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import {
    CalendarIcon,
    OfficeBuildingIcon,
    LogoutIcon,
    MapIcon,
    MenuIcon,
    UserGroupIcon,
    XIcon,
} from '@heroicons/react/outline'
import { ChevronLeftIcon, FilterIcon, SearchIcon } from '@heroicons/react/solid'

import { getMyTeam } from '../utilities/ArrayFunctions'
import { COLOR_PRM, COLOR_SEC } from '../utilities/Constants';
import logo from '../assets/img/logo-4.png'
import logo_menu from '../assets/img/logo-menu.png'
import avatarDfl from '../assets/img/avatar-1.png';
import BrokerCardSml from './BrokerCardSml'
import ProfileDetails from './ProfileDetails'
import ProfileHeader from './ProfileHeader'
import OfferCard from './OfferCard'
import OrderCard from './OrderCard'
import MatchOfferCard from './MatchOfferCard';
import { showNotification } from '../utilities/ShowNotifications';
import { startLoadingEnviroment } from '../actions/auth';
import { useDispatch } from 'react-redux';
import MatchOrderCard from './MatchOrderCard';

const navigation = [
    { name: 'Gestion BH', option: 1, icon: OfficeBuildingIcon, current: false, titulo: 'GESTION GENERAL BH' },
    { name: 'Agenda', option: 2, icon: CalendarIcon, current: false, titulo: 'GESTION DE TAREAS' },
    { name: 'Ofertas', option: 3, icon: MapIcon, current: false, titulo: 'MAPA DE OFERTAS' },
    { name: 'Pedidos', option: 4, icon: MapIcon, current: false, titulo: 'MAPA DE PEDIDOS' },
]
const secondaryNavigation = [
    { name: 'Cerrar Sesión', action: 1, icon: LogoutIcon },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Profile({ broker, directorio, ofertas, directorio_ini, equipo, ordenes, handleLogout, curOption, setCurOption, setCurOffer, setCurOrder, matches_ofr, matches_ord }) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [curBroker, setCurBroker] = useState(broker)
    const [offerList, setOfferList] = useState(ofertas)
    const [orderList, setOrderList] = useState(ordenes)
    const [curTeam, setCurTeam] = useState(equipo)
    const [findOffer, setFindOffer] = useState('')
    const [findOrder, setFindOrder] = useState('')
    const [showMatch, setShowMatch] = useState(1);

    const dispatch = useDispatch();

    const key = 'updatable';

    const handleBrokerChg = (option, newBroker) => {
        if (option === 6) {
            setCurBroker(newBroker)
            if (newBroker.brk_is_parent > 0 || newBroker.brk_parent > 0) {
                setCurTeam(getMyTeam(newBroker.id, newBroker.brk_is_parent, newBroker.brk_parent, directorio))
            } else {
                setCurTeam([])
            }
            setCurOption(6)
        } else {
            setCurBroker(broker)
            setCurTeam(equipo)
            setCurOption(1)
        }
    }

    useEffect(() => {
        if (findOffer.trim().length > 4) {
            const filteredOffer = ofertas.filter(function (oferta) {
                return oferta.ofr_criterio.toLowerCase().indexOf(findOffer.toLowerCase()) > -1;
            })
            setOfferList(filteredOffer.length > 0 ? filteredOffer : []);
        } else {
            setOfferList(ofertas);
        }
    }, [findOffer, ofertas]);

    useEffect(() => {
        if (findOrder.trim().length > 4) {
            const filteredOrder = ordenes.filter(function (orden) {
                return orden.ord_criterio.toLowerCase().indexOf(findOrder.toLowerCase()) > -1;
            })
            setOrderList(filteredOrder.length > 0 ? filteredOrder : []);
        } else {
            setOrderList(ordenes);
        }
    }, [findOrder, ordenes]);

    const showMessage = (titulo) => {
        showNotification(
            "topLeft",
            1,
            titulo,
            "Hey Colega!... esta opcion pronto estará habilitada... lindo día!"
        );
    };

    const handleReload = (newOption) => {
        dispatch(startLoadingEnviroment(broker.id_mobile, broker.brk_name));
        setCurOption(newOption);
    }

    return (

        <div className="h-screen flex">
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex z-40">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                    <div className="flex-shrink-0 flex items-center px-4">
                                        <img
                                            className="h-10 w-auto"
                                            src={logo_menu}
                                            alt="Workflow"
                                        />
                                        <h2 className='text-xl ml-2 text-gray-900'>Brokerhood</h2>
                                    </div>
                                    <nav aria-label="Sidebar" className="mt-5">
                                        <div className="px-2 space-y-1">
                                            {navigation.map((item) => (
                                                <div
                                                    key={item.name}
                                                    onClick={() => showMessage(item.titulo)}
                                                    className={classNames(
                                                        item.current
                                                            ? 'bg-gray-100 text-gray-900'
                                                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                                    )}
                                                    aria-current={item.current ? 'page' : undefined}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                            'mr-4 h-6 w-6'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </div>
                                            ))}
                                        </div>
                                        <hr className="border-t border-gray-200 my-5" aria-hidden="true" />
                                        <div className="px-2 space-y-1">
                                            {secondaryNavigation.map((item) => (
                                                <button
                                                    key={item.name}
                                                    onClick={() => item.action === 1 ? handleLogout() : null}
                                                    className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                                >
                                                    <item.icon
                                                        className="text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </button>
                                            ))}
                                        </div>
                                    </nav>
                                </div>
                                <div className="flex-shrink-0 flex border-t border-gray-200 p-4" onClick={() => handleBrokerChg(1, broker)}>
                                    <div className="flex-shrink-0 group block">
                                        <div className="flex items-center">
                                            <div>
                                                {broker.brk_avatar
                                                    ? <img className="h-10 w-10 rounded-full border-2 border-solid border-purple-200" src={broker.brk_avatar} alt="" />
                                                    : <img className="h-10 w-10 rounded-full" src={avatarDfl} alt="" />
                                                }
                                            </div>
                                            <div className="ml-3">
                                                <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">{broker.brk_name}</p>
                                                <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">Mi perfil</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Force sidebar to shrink to fit close icon */}
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:flex lg:flex-shrink-0">
                <div className="flex flex-col w-64">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-gray-100">
                        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                            <div className="flex items-center flex-shrink-0 px-4">
                                <img
                                    className="h-24 w-auto m-auto"
                                    src={logo}
                                    alt="Workflow"
                                />
                            </div>
                            <nav className="mt-5 flex-1" aria-label="Sidebar">
                                <div className="px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <div
                                            key={item.name}
                                            onClick={() => showMessage(item.titulo)}
                                            className={classNames(
                                                item.current
                                                    ? 'bg-gray-200 text-gray-900'
                                                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                            )}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                                    'mr-3 flex-shrink-0 h-6 w-6'
                                                )}
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </div>
                                    ))}
                                </div>
                                <hr className="border-t border-gray-200 my-5" aria-hidden="true" />
                                <div className="flex-1 px-2 space-y-1">
                                    {secondaryNavigation.map((item) => (
                                        <div
                                            key={item.name}
                                            onClick={() => item.action === 1 ? handleLogout() : null}
                                            className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                            <item.icon
                                                className="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6"
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </div>
                                    ))}
                                </div>
                            </nav>
                        </div>
                        <div className="flex-shrink-0 flex border-t border-gray-200 p-4" onClick={() => handleBrokerChg(1, broker)}>
                            <div className="flex-shrink-0 w-full group block">
                                <div className="flex items-center">
                                    <div>
                                        {broker.brk_avatar
                                            ? <img className="h-10 w-10 rounded-full border-2 border-solid border-purple-200" src={broker.brk_avatar} alt="" />
                                            : <img className="h-10 w-10 rounded-full" src={avatarDfl} alt="" />
                                        }
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900 truncate">{broker.brk_name}</p>
                                        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">Mi perfil</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
                <div className="lg:hidden">
                    <div className="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5">
                        <div>
                            <img
                                className="h-10 w-auto"
                                src={logo_menu}
                                alt="Workflow"
                            />
                        </div>
                        <div>
                            <button
                                type="button"
                                className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-600"
                                onClick={() => setSidebarOpen(true)}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <MenuIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex-1 relative z-0 flex overflow-hidden">
                    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
                        {/* Breadcrumb */}
                        <nav className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden" aria-label="Breadcrumb">
                            <div className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
                                <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span>Comunidad</span>
                            </div>
                        </nav>

                        <article>
                            <ProfileHeader broker={broker} curBroker={curBroker} curOption={curOption} setCurOption={setCurOption} />

                            {curOption < 6 ?
                                <div className="mt-6 sm:mt-2 2xl:mt-5">
                                    <div className="border-b border-gray-200">
                                        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                                            <nav className="-mb-px flex space-between" aria-label="Tabs">
                                                <Button type="text" onClick={() => setCurOption(1)} style={{ color: curOption === 1 ? '#6b21a8' : '#94a3b8' }}>Mi perfil</Button>
                                                <Button type="text" onClick={() => handleReload(2)} style={{ color: curOption === 2 ? '#6b21a8' : '#94a3b8' }}>Ofertas</Button>
                                                <Button type="text" onClick={() => handleReload(3)} style={{ color: curOption === 3 ? '#6b21a8' : '#94a3b8' }}>Pedidos</Button>
                                                <Button type="text" onClick={() => handleReload(4)} style={{ color: curOption === 4 ? '#6b21a8' : '#94a3b8' }}>Matches</Button>
                                                <Button type="text" onClick={() => handleReload(5)} style={{ color: curOption === 5 ? '#6b21a8' : '#94a3b8' }}>Agenda</Button>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }
                            {/* Description list */}
                            {curOption === 1 || curOption === 6 ? (
                                <ProfileDetails curBroker={curBroker} team={curTeam} handleBrokerChg={handleBrokerChg} />
                            ) : curOption === 2 ? (
                                <div className="flex min-h-full flex-col">
                                    <div className="flex-1 flex border-b border-purple-200 mx-2 mt-2">
                                        <div className="w-full flex md:ml-2">
                                            <label htmlFor="desktop-search-field" className="sr-only">
                                                Buscar Oferta
                                            </label>
                                            <label htmlFor="mobile-search-field" className="sr-only">
                                                Buscar Oferta
                                            </label>
                                            <div className="relative w-full text-gray-400 focus-within:text-purple-600">
                                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                                                    <SearchIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                                                </div>
                                                <input
                                                    name="mobile-search-field"
                                                    id="mobile-search-field"
                                                    className="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:hidden"
                                                    placeholder={"Buscar Oferta entre " + offerList.length + " - (Minimo 5 caracteres)"}
                                                    type="search"
                                                    value={findOffer}
                                                    onChange={(e) => setFindOffer(e.target.value)}
                                                />
                                                <input
                                                    name="desktop-search-field"
                                                    id="desktop-search-field"
                                                    className="hidden h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:block"
                                                    placeholder={"Buscar Oferta entre " + offerList.length + " - (Minimo 5 caracteres)"}
                                                    type="search"
                                                    value={findOffer}
                                                    onChange={(e) => setFindOffer(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {offerList.length > 0
                                        ? (offerList.map(
                                            (offer) => (
                                                <OfferCard
                                                    key={offer.id}
                                                    offer={offer}
                                                    setCurOffer={setCurOffer}
                                                    setCurOption={setCurOption}
                                                    showMessage={showMessage}
                                                />
                                            )
                                        ))
                                        :
                                        (<div className='mt-10 border-b border-purple-200 mx-2 pb-6'>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>)}
                                </div>
                            ) : curOption === 3 ? (
                                <div className="flex min-h-full flex-col">
                                    <div className="flex-1 flex border-b border-purple-200 mx-2 mt-2">
                                        <div className="w-full flex md:ml-2">
                                            <label htmlFor="desktop-search-field" className="sr-only">
                                                Buscar Pedido
                                            </label>
                                            <label htmlFor="mobile-search-field" className="sr-only">
                                                Buscar Pedido
                                            </label>
                                            <div className="relative w-full text-gray-400 focus-within:text-purple-600">
                                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                                                    <SearchIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                                                </div>
                                                <input
                                                    name="mobile-search-field"
                                                    id="mobile-search-field"
                                                    className="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:hidden"
                                                    placeholder={"Buscar Pedido entre " + orderList.length + " - (Minimo 5 caracteres)"}
                                                    type="search"
                                                    value={findOrder}
                                                    onChange={(e) => setFindOrder(e.target.value)}
                                                />
                                                <input
                                                    name="desktop-search-field"
                                                    id="desktop-search-field"
                                                    className="hidden h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:block"
                                                    placeholder={"Buscar Pedido entre " + orderList.length + " - (Minimo 5 caracteres)"}
                                                    type="search"
                                                    value={findOrder}
                                                    onChange={(e) => setFindOrder(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {orderList.length > 0
                                        ? (orderList.map(
                                            (order) => (
                                                <OrderCard
                                                    key={order.id}
                                                    order={order}
                                                    setCurOrder={setCurOrder}
                                                    setCurOption={setCurOption}
                                                    showMessage={showMessage}
                                                />
                                            )
                                        ))
                                        :
                                        (<div className='mt-10 border-b border-purple-200 mx-2 pb-6'>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </div>)}
                                </div>
                            ) : curOption === 4 ? (
                                <div className="flex min-h-full flex-col">
                                    <div className="flex-1 flex border-b border-purple-200 mx-2 mt-2">
                                        <div className="mt-2 sm:mt-2 2xl:mt-2">
                                            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                                                <nav className="-mb-px flex space-between" aria-label="Tabs">
                                                    <Button type="text" onClick={() => setShowMatch(1)} style={{ color: showMatch === 1 ? '#6b21a8' : '#94a3b8' }}>Con Ofertas</Button>
                                                    <Button type="text" onClick={() => setShowMatch(2)} style={{ color: showMatch === 2 ? '#6b21a8' : '#94a3b8' }}>Con Pedidos</Button>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        showMatch === 1 ? (
                                            matches_ofr.length > 0
                                                ? (matches_ofr.map(
                                                    (match) => (
                                                        <MatchOfferCard
                                                            key={match.id}
                                                            match={match}
                                                            setCurOffer={setCurOffer}
                                                            setCurOption={setCurOption}
                                                            showMessage={showMessage}
                                                        />
                                                    )
                                                ))
                                                :
                                                (<div className='mt-10 border-b border-purple-200 mx-2 pb-6'>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                </div>)
                                        ) : (
                                            matches_ord.length > 0
                                                ? (matches_ord.map(
                                                    (match) => (
                                                        <MatchOrderCard
                                                            key={match.id}
                                                            match={match}
                                                            setCurOffer={setCurOffer}
                                                            setCurOption={setCurOption}
                                                            showMessage={showMessage}
                                                        />
                                                    )
                                                ))
                                                :
                                                (<div className='mt-10 border-b border-purple-200 mx-2 pb-6'>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                </div>)
                                        )
                                    }
                                </div>
                            ) : (null)}

                        </article>
                    </main>
                    <aside className="hidden xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
                        <div className="px-6 pt-6 pb-4">
                            <h2 className="text-lg font-medium text-gray-900">Comunidad</h2>
                            <p className="mt-1 text-sm text-gray-600">Buscar entre {directorio.length} colegas</p>
                            <form className="mt-6 flex space-x-4" action="#">
                                <div className="flex-1 min-w-0">
                                    <label htmlFor="search" className="sr-only">
                                        Search
                                    </label>
                                    <div className="relative rounded-md shadow-sm">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                        <input
                                            type="search"
                                            name="search"
                                            id="search"
                                            className="focus:ring-purple-500 focus:border-purple-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                            placeholder="Search"
                                        />
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                >
                                    <FilterIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <span className="sr-only">Search</span>
                                </button>
                            </form>
                        </div>
                        {/* Directory list */}
                        <nav className="flex-1 min-h-0 overflow-y-auto" aria-label="Directory">
                            {Object.keys(directorio_ini).map((letter) => (
                                <div key={letter} className="relative">
                                    <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                                        <h3>{letter}</h3>
                                    </div>
                                    <ul className="relative z-0 divide-y divide-gray-200">
                                        {directorio_ini[letter].map((person) => (
                                            <li key={person.id}>
                                                <BrokerCardSml person={person} handleBrkerChg={handleBrokerChg} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </nav>
                    </aside>
                </div>
            </div>
            {curOption === 2 ? (
                <Fab aria-label="add" style={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    backgroundColor: COLOR_PRM
                }}
                    onClick={() => setCurOption(7)}
                >
                    <AddIcon style={{ color: 'white' }} />
                </Fab>
            ) : curOption === 3 ? (
                <Fab aria-label="add" style={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    backgroundColor: COLOR_SEC
                }}
                    onClick={() => setCurOption(9)}
                >
                    <AddIcon style={{ color: 'white' }} />
                </Fab>
            ) : null}

        </div>
    )
}