import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrokerHood } from './BrokerHood';
import 'antd/dist/antd.min.css';
import './index.css';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrokerHood />
  </React.StrictMode>
);
