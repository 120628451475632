import avatarDfl from '../assets/img/avatar-1.png';

function BrokerCardSml({ person, handleBrkerChg }) {
    return (
        <div className="group relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-500" onClick={() => handleBrkerChg(6, person)}>
            <div className="flex-shrink-0">
                {person.brk_avatar
                    ? <img className="h-12 w-12 rounded-full border-2 border-solid border-purple-200" src={person.brk_avatar} alt="" />
                    : <img className="h-12 w-12 rounded-full" src={avatarDfl} alt="" />
                }
            </div>
            <div className="flex-1 min-w-0">
                <a href="#" className="focus:outline-none">
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900">{person.brk_name}</p>
                    <p className="text-sm text-gray-500 truncate">{person.brk_cargo}</p>
                    <p className="text-sm text-gray-500 truncate">{person.brk_company}</p>
                </a>
            </div>
        </div>
    )
}

export default BrokerCardSml