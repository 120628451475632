function SmallCard({ img, handleViewImage }) {
    return (
        <div className="flex items-center m-2 space-x-4 mt-5 rounded-xl cursor-pointer hover:bg-gray-100 hover:scale-105 transition transform duration-200 ease-out" onClick={() => handleViewImage(img)}>
            <div className="relative h-32 w-32">
                <img src={img} layout="fill" className="w-full h-full object-center object-cover rounded-2xl" alt='...' />
            </div>
        </div>
    );
}

export default SmallCard;