import { auth, googleAuthProvider, signInWithPopup, createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword, signOut } from '../firebase/firebaseConfig';
import { loadEnviroment } from '../helpers/loadEnviroment';
import { types } from '../types/types';
import { initEnviroment, outEnviroment } from './enviroment';
import { finishLoading, startLoading } from './ui';

export const startLoginEmailPassword = (email, password) => {
    return (dispatch) => {

        dispatch(startLoading());

        signInWithEmailAndPassword(auth, email, password)
            .then(({ user }) => {
                dispatch(startLoadingEnviroment(user.uid, user.displayName));

                dispatch(finishLoading());
            })
            .catch(e => {
                console.log(e);
                dispatch(finishLoading());
                //Swal.fire('Error', e.message, 'error');
            })
    }
}

export const startRegisterWithEmailPasswordName = (email, password, name) => {
    return (dispatch) => {

        createUserWithEmailAndPassword(auth, email, password)
            .then(async ({ user }) => {

                await updateProfile(auth.currentUser, { displayName: name });

                dispatch(
                    login(user.uid, user.displayName)
                );
            })
            .catch(e => {
                console.log(e);
                //Swal.fire('Error', e.message, 'error');
            })

    }
}

export const startGoogleLogin = () => {
    return (dispatch) => {
        signInWithPopup(auth, googleAuthProvider)
            .then(({ user }) => {
                dispatch(
                    login(user.uid, user.displayName)
                )
                // This gives you a Google Access Token. You can use it to access the Google API.
                //const credential = GoogleAuthProvider.credentialFromResult(userCred);
                //const token = credential.accessToken;
                // The signed-in user info.
                //const user = userCred.user;
                // ...
            });
        /*.catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });*/
    }
}

export const startLoadingEnviroment = (uid, displayName) => {
    return async (dispatch) => {
        const datosEnv = await loadEnviroment(uid);

        if (datosEnv) {
            dispatch(initEnviroment(datosEnv));
            dispatch(login(uid, displayName));
        } else {
            dispatch(startLogout());
        }
    }
}


export const login = (uid, displayName) => ({
    type: types.login,
    payload: {
        uid,
        displayName
    }
})

export const startLogout = () => {
    return async (dispatch) => {
        await signOut(auth);

        dispatch(logout());
        dispatch(outEnviroment());
    }
}


export const logout = () => ({
    type: types.logout
})
