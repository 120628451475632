export const types = {

    login: '[Auth] Login',
    logout: '[Auth] Logout',

    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',

    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',

    envLoad: '[ENV] Env Load',
    envUpdate: '[ENV] Env Update',
    envLogout: '[ENV] Env Logout',

    offerAdNew: '[Offer] New offer',
    offerActive: '[Offer] Set active offer',
    offerLoad: '[Offer] Load offer',
    offerUpdated: '[Offer] Update offer',
    offerFileUrl: '[Offer] Updated image url',
    offerDelete: '[Offer] Delete offer',
    offerLogoutCleaning: '[Offer] Logout Cleaning',

    oderAdNew: '[Order] New order',
    oderActive: '[Order] Set active order',
    oderLoad: '[Order] Load order',
    oderUpdated: '[Order] Update order',
    oderFileUrl: '[Order] Updated image url',
    oderDelete: '[Order] Delete order',
    oderLogoutCleaning: '[Order] Logout Cleaning',

    matchActive: '[Match] Set active match',
    matchLoad: '[Match] Load match',
    matchUpdated: '[Match] Update match',
    matchDelete: '[Match] Delete match',
    matchLogoutCleaning: '[Match] Logout Cleaning',

    contactActive: '[Contact] Set active contact',
    contactLoad: '[Contact] Load contact',
    contactUpdated: '[Contact] Update contact',
    contactDelete: '[Contact] Delete contact',
    contactLogoutCleaning: '[Contact] Logout Cleaning',

}