import { showNotification } from "../utilities/ShowNotifications";
import SRV from './Service';

export const loadEnviroment = async (uid) => {

    try {
        let datos = await SRV.getBroker(uid);
        if (datos) {
            return datos;
        } else {
            showNotification(
                "topRight",
                2,
                "SINCRONIZAR DATOS",
                "NO SE ENCONTRARON DATOS... INTENTE MAS TARDE"
            );
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}